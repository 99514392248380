import React, { useContext } from "react";
import { AuthContext } from '../../contexts/AuthContext'
import { Col, Row, Button } from "reactstrap";
import Footer from "../../layout/Footer/Footer";
import Header from "../../layout/Header/Header";

function BotonesInventarios() {
  const { user } = useContext(AuthContext);
  return (
    <>
          <Header />
          {user.menu_inventarios ?(
      <div className="container">
        <br />
        <br />
          <Row>
          <Col md={4} align="center">
          {user.inventarios ?(
            <Button href="/ListadoInventarios" className="botonesMenu" color="success">
              <i class="fas fa-warehouse fa-7x"></i>
              <br />
              <br />
              Inventario
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-warehouse fa-7x"></i>
          <br />
          <br />
          Inventario
        </Button> }
          </Col>
          <Col md={4} align="center">
          {user.menu_disponible ?(
            <Button href="/ListadoDisponible" className="botonesMenu" color="success">
              <i class="fas fa-tasks fa-7x"></i>
              <br />
              <br />
              Disponible
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-tasks fa-7x"></i>
          <br />
          <br />
          Disponible
        </Button> }
          </Col>
          <Col md={4} align="center">
          {user.disponible_vendedores ?(
            <Button href="/ListadoDisponibleVendedor" className="botonesMenu" color="success">
              <i class="fas fa-list-alt fa-7x"></i>
              <br />
              <br />
              Disponible Vendedor
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-list-alt fa-7x"></i>
          <br />
          <br />
          Disponible Vendedor
        </Button> }
          </Col>
          </Row>
          <br />
          <Row>
          <Col md={4} align="center">
          {user.inventarios ?(
            <Button href="/ListadoSeguimiento" className="botonesMenu" color="success">
              <i class="fas fa-search fa-7x"></i>
              <br />
              <br />
              Seguimiento
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-search fa-7x"></i>
          <br />
          <br />
          Seguimiento
        </Button> }
          </Col>

          <Col md={4} align="center">
          {user.inventarios ?(
            <Button href="/ListadoPendRecibir" className="botonesMenu" color="success">
              <i class="fas fa-plane-arrival fa-7x"></i>
              <br />
              <br />
              Pendiente Recibir
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-plane-arrival fa-7x"></i>
          <br />
          <br />
          Pendiente Recibir
        </Button> }
          </Col>

        </Row>
      </div>
      ): undefined } 
      
    </>
  );
}

export default BotonesInventarios;
