import React, { useContext, useState, useEffect } from "react";
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import PrivateRoute from './PrivateRoute'
import Home from '../components/layout/Home/Home'
import Inicio from '../components/layout/Home/Inicio'
import Login from '../components/layout/Login/Login'
import ListadoUsuarios from '../components/layout/Login/ListadoUsuarios'
import Logout from '../components/layout/Login/Logout'
import AuthContextProvider from "./contexts/AuthContext.js";

// {/* Catalogos */}
import BotonesCatalogos from "./views/Catalogos/BotonesCatalogos"
import ListadoPeriodosAdmin from './views/Catalogos/PeriodosAdmin/ListadoPeriodosAdmin'
import ListadoAreas from './views/Catalogos/Areas/ListadoAreas'
import ListadoDepartamentos from './views/Catalogos/Departamentos/ListadoDepartamentos'
import PeriodosAdminCreate from './views/Catalogos/PeriodosAdmin/PeriodosAdminCreate'
import ListadoPlanteles from './views/Catalogos/Planteles/ListadoPlanteles'
import ListadoCiclos from './views/Catalogos/Ciclos/ListadoCiclos'
import ListadoProveedores from './views/Catalogos/Proveedores/ListadoProveedores'
import ProveedoresCreate from './views/Catalogos/Proveedores/ProveedoresCreate'

// {/* Articulos */}
import BotonesArticulos from "./views/Articulos/BotonesArticulos"
import ListadoColores from './views/Articulos/Colores/ListadoColores'
import ListadoTiposTelas from './views/Articulos/TiposTelas/ListadoTiposTelas'
import ListadoArticulos from './views/Articulos/Articulos/ListadoArticulos'
import ArticulosCreate from './views/Articulos/Articulos/ArticulosCreate'

// {/* Inventarios */}
import BotonesInventarios from "./views/Inventarios/BotonesInventarios"
import ListadoInventarios from './views/Inventarios/Inventarios/ListadoInventarios'
import ListadoDisponible from "./views/Inventarios/Inventarios/ListadoDisponible";
import ListadoSeguimiento from "./views/Inventarios/Inventarios/ListadoSeguimiento";
import ListadoDisponibleVendedor from "./views/Inventarios/Inventarios/ListadoDisponibleVendedor";
import ListadoPendRecibir from "./views/Inventarios/Inventarios/ListadoPendRecibir";

// {/* Compras */}
import BotonesCompras from "./views/Compras/BotonesCompras"
import ListadoOrdenesCompra from './views/Compras/OrdenesCompra/ListadoOrdenesCompra'
import OrdenesCompraCreate from './views/Compras/OrdenesCompra/OrdenesCompraCreate'
import ListadoCompras from "./views/Compras/Compras/ListadoCompras";
import ComprasCreate from "./views/Compras/Compras/ComprasCreate";
import ListadoDevolucionesProveedores from "./views/Compras/DevolucionesProveedores/ListadoDevolucionesProveedores";
import DevolucionesProveedoresCreate from "./views/Compras/DevolucionesProveedores/DevolucionesProveedoresCreate";

// {/* Ventas */}
import BotonesVentas from "./views/Ventas/BotonesVentas"
import ListadoPedidos from './views/Ventas/Pedidos/ListadoPedidos'
import PedidosCreate from './views/Ventas/Pedidos/PedidosCreate'
import ListadoSurtidos from './views/Ventas/Surtidos/ListadoSurtidos'
// import SurtidosCreate from './views/Ventas/Surtidos/SurtidosCreate'
import ListadoDevoluciones from './views/Ventas/Devoluciones/ListadoDevoluciones'
import DevolucionesCreate from './views/Ventas/Devoluciones/DevolucionesCreate'
import DevolucionesPedidoCreate from './views/Ventas/Devoluciones/DevolucionesPedidoCreate'
import ListadoApartados from './views/Ventas/Apartados/ListadoApartados'
import ApartadosCreate from './views/Ventas/Apartados/ApartadosCreate'

// {/* Admin */}
import BotonesConfigAdmin from "./views/ConfigAdmin/BotonesConfigAdmin"
import ListadoPresupuestos from './views/ConfigAdmin/Presupuestos/ListadoPresupuestos'
import PresupuestosCreate from './views/ConfigAdmin/Presupuestos/PresupuestosCreate'
import ListadoGastos from './views/ConfigAdmin/Gastos/ListadoGastos'
import GastosCreate from './views/ConfigAdmin/Gastos/GastosCreate'

//Nominas
import BotonesNominas from './views/Empleados/BotonesNominas'
import ListadoNominas from "./views/Empleados/Nominas/ListadoNominas"
import ListadoColaboradores from "./views/Empleados/Colaboradores/ListadoColaboradores"
import ListadoFaltasColaboradores from './views/Empleados/Faltas/ListadoFaltasColaboradores'
import FaltasColaboradoresCreate from './views/Empleados/Faltas/FaltasColaboradoresCreate'
import ListadoPuestos from "./views/Empleados/Puestos/ListadoPuestos"
import NominasCreate from "./views/Empleados/Nominas/NominasCreate"
import ColaboradoresCreate from "./views/Empleados/Colaboradores/ColaboradoresCreate"

// Clientes
import BotonesClientes from './views/Clientes/BotonesClientes'
import BotonesCatalogosFacturas from './views/Clientes/Facturas/Catalogos/BotonesCatalogosFacturas'
import ListadoClientes from "./views/Clientes/Clientes/ListadoClientes"
import ClientesCreate from "./views/Clientes/Clientes/ClientesCreate"
import FacturasCreate from "./views/Clientes/Facturas/FacturasCreate"
import ListadoFacturas from "./views/Clientes/Facturas/ListadoFacturas"
import ListadoDetalleFacturas from "./views/Clientes/Facturas/ListadoDetalleFacturas"
import ListadoGrupos from './views/Clientes/Facturas/Catalogos/Grupos/ListadoGrupos'
import AbonosFacturasCreate from "./views/Clientes/Facturas/AbonosFacturasCreate"
import ListadoAbonosFacturas from './views/Clientes/Facturas/ListadoAbonosFacturas'
import ListadoCobranza from "./views/Clientes/Facturas/ListadoCobranza"

//Reportes
import BotonesReportes from './views/Reportes/BotonesReportes'
import GraficaArticulos from "./views/Reportes/GraficaArticulosV4";
import GraficaMensual from "./views/Reportes/GraficaMensualV4";
import GraficaVentas from "./views/Reportes/GraficaVentas";
import TablaVentas from "./views/Reportes/TablaVentas";
import TablaDevoluciones from "./views/Reportes/TablaDevoluciones";

// Edo Cta Clientes
import EdoCtaVistaClientes from './views/Clientes/Clientes/LoginCliente/EdoCtaVistaCliente'

// Logs
import Logs from './views/Logs/ListadoLogs'
// Config
import Configuraciones from './views/Configuracion/Configuraciones'

// Admin
import BotonesAdmin from "./views/Admin/BotonesAdmin";
import ListadoBancos from "./views/Admin/Bancos/ListadoBancos";
import AbonosCreate from './views/Admin/Abonos/AbonosCreate'
import ListadoAbonos from './views/Admin/Abonos/ListadoAbonos'
import EstadoCuenta from "./views/Admin/EstadoCuenta/EstadoCuenta";
import ListadoVentas from "./views/Admin/Ventas/ListadoVentas";
import NotasCargoCreate from './views/Admin/NotasCargo/NotasCargoCreate'
import ListadoNotasCargo from './views/Admin/NotasCargo/ListadoNotasCargo'
import ListadoDevolucionesAdmin from './views/Admin/DevolucionesAdmin/ListadoDevolucionesAdmin'

// CXP
import BotonesCXP from "./views/CXP/BotonesCXP";
import EstadoCuentaCXP from "./views/CXP/EstadoCuentaCXP/EstadoCuentaCXP";
import ListadoComprasCXP from "./views/CXP/ComprasCXP/ListadoComprasCXP";
import AbonosProveedoresCreate from './views/CXP/AbonosProveedores/AbonosProveedoresCreate'
import ListadoAbonosProveedores from './views/CXP/AbonosProveedores/ListadoAbonosProveedores'
import ListadoDevolucionesProveedoresAdmin from './views/CXP/DevolucionesProveedoresAdmin/ListadoDevolucionesProveedoresAdmin'


function Routes() {
    return (
        <AuthContextProvider>
            <BrowserRouter forceRefresh={true}>
            <Switch>
                <Route exact path ="/" component={Home} />
                <Route exact path ="/Inicio" component={Inicio} />
                <Route exact path ="/Login" component={Login} />
                <Route exact path ="/Logout" component={Logout} />
                <Route exact path ="/MenuUsuarios" component={ListadoUsuarios} />
                {/* Catalogos */}
                <Route exact path ="/MenuCatalogos" component={BotonesCatalogos} />
                <Route exact path ="/Planteles" component={ListadoPlanteles} />
                <Route exact path ="/Areas" component={ListadoAreas} />
                <Route exact path ="/Ciclos" component={ListadoCiclos} />
                <Route exact path ="/PeriodosAdmin" component={ListadoPeriodosAdmin} />
                <Route exact path ="/Departamentos" component={ListadoDepartamentos} />
                <Route exact path ="/PeriodosAdminCreate" component={PeriodosAdminCreate} />
                <Route exact path ="/Proveedores" component={ListadoProveedores} />
                <Route exact path ="/ProveedoresCreate" component={ProveedoresCreate} />
                {/* Articulos */}
                <Route exact path ="/MenuArticulos" component={BotonesArticulos} />
                <Route exact path ="/Colores" component={ListadoColores} />
                <Route exact path ="/TiposTelas" component={ListadoTiposTelas} />
                <Route exact path ="/ListadoArticulos" component={ListadoArticulos} />
                <Route exact path ="/ArticulosCreate" component={ArticulosCreate} />
                {/* Inventarios */}
                <Route exact path ="/MenuInventarios" component={BotonesInventarios} />
                <Route exact path ="/ListadoInventarios" component={ListadoInventarios} />
                <Route exact path ="/ListadoDisponible" component={ListadoDisponible} />
                <Route exact path ="/ListadoDisponibleVendedor" component={ListadoDisponibleVendedor} />
                <Route exact path ="/ListadoSeguimiento" component={ListadoSeguimiento} />
                <Route exact path ="/ListadoPendRecibir" component={ListadoPendRecibir} />
                {/* Compras */}
                <Route exact path ="/MenuCompras" component={BotonesCompras} />
                <Route exact path ="/ListadoOrdenesCompra" component={ListadoOrdenesCompra} />
                <Route exact path ="/OrdenesCompraCreate" component={OrdenesCompraCreate} />
                <Route exact path ="/ListadoCompras" component={ListadoCompras} />
                <Route exact path ="/ComprasCreate" component={ComprasCreate} />
                <Route exact path ="/ListadoDevolucionesProveedores" component={ListadoDevolucionesProveedores} />
                <Route exact path ="/DevolucionesProveedoresCreate" component={DevolucionesProveedoresCreate} />
                {/* Ventas */}
                <Route exact path ="/MenuVentas" component={BotonesVentas} />
                <Route exact path ="/ListadoPedidos" component={ListadoPedidos} />
                <Route exact path ="/PedidosCreate" component={PedidosCreate} />
                <Route exact path ="/ListadoSurtidos" component={ListadoSurtidos} />
                {/* <Route exact path ="/SurtidosCreate" component={SurtidosCreate} /> */}
                <Route exact path ="/ListadoDevoluciones" component={ListadoDevoluciones} />
                <Route exact path ="/DevolucionesCreate" component={DevolucionesCreate} />
                <Route exact path ="/DevolucionesPedidoCreate" component={DevolucionesPedidoCreate} />
                <Route exact path ="/ListadoApartados" component={ListadoApartados} />
                <Route exact path ="/ApartadosCreate" component={ApartadosCreate} />
                {/* Admin */}
                <Route exact path ="/MenuGastos" component={BotonesConfigAdmin} />
                <Route exact path ="/Presupuestos" component={ListadoPresupuestos} />
                <Route exact path ="/PresupuestosCreate" component={PresupuestosCreate} />
                <Route exact path ="/MenuNominas" component={BotonesNominas} />
                <Route exact path ="/Nominas" component={ListadoNominas} />
                <Route exact path ="/Empleados" component={ListadoColaboradores} />
                <Route exact path ="/EmpleadosCreate" component={ColaboradoresCreate} />
                <Route exact path ="/FaltasColaboradores" component={ListadoFaltasColaboradores} />
                <Route exact path ="/FaltasColaboradoresCreate" component={FaltasColaboradoresCreate} />
                <Route exact path ="/NominasCreate" component={NominasCreate} />
                <Route exact path ="/Puestos" component={ListadoPuestos} />
                <Route exact path ="/Gastos" component={ListadoGastos} />
                <Route exact path ="/GastosCreate" component={GastosCreate} />
                {/* Clientes */}
                <Route exact path ="/MenuClientes" component={BotonesClientes} />
                <Route exact path ="/CatalogosFacturas" component={BotonesCatalogosFacturas} />
                <Route exact path ="/Clientes" component={ListadoClientes} />
                <Route exact path ="/ClientesCreate" component={ClientesCreate} />
                <Route exact path ="/NotasCreate" component={FacturasCreate} />
                <Route exact path ="/EdoCtaClientes" component={ListadoFacturas} />
                <Route exact path ="/DetalleNotas" component={ListadoDetalleFacturas} />
                <Route exact path ="/Zonas" component={ListadoGrupos} />
                <Route exact path ="/AbonosNotasCreate" component={AbonosFacturasCreate} />
                <Route exact path ="/AbonosClientes" component={ListadoAbonosFacturas} />
                <Route exact path ="/ListadoCobranza" component={ListadoCobranza} />
                {/* Reportes */}
                <Route exact path ="/MenuReportes" component={BotonesReportes} />
                <Route exact path ="/GraficaArticulos" component={GraficaArticulos} />
                <Route exact path ="/GraficaMensual" component={GraficaMensual} />
                <Route exact path ="/GraficaVentas" component={GraficaVentas} />
                <Route exact path ="/TablaVentas" component={TablaVentas} />
                <Route exact path ="/TablaDevoluciones" component={TablaDevoluciones} />
                {/* Edo Cta Clientes  */}
                <Route exact path ="/EdoCtaVistaClientes" component={EdoCtaVistaClientes} />
                {/* Logs  */}
                <Route exact path ="/MenuLogs" component={Logs} />
                {/* Config  */}
                <Route exact path ="/MenuConfiguraciones" component={Configuraciones} />
                {/* Admin */}
                <Route exact path ="/MenuAdmin" component={BotonesAdmin} />
                <Route exact path ="/Bancos" component={ListadoBancos} />
                <Route exact path ="/AbonosCreate" component={AbonosCreate} />
                <Route exact path ="/ListadoAbonos" component={ListadoAbonos} />
                <Route exact path ="/EstadoCuenta" component={EstadoCuenta} />
                <Route exact path ="/ListadoVentas" component={ListadoVentas} />
                <Route exact path ="/NotasCargoCreate" component={NotasCargoCreate} />
                <Route exact path ="/ListadoNotasCargo" component={ListadoNotasCargo} />
                <Route exact path ="/ListadoDevolucionesAdmin" component={ListadoDevolucionesAdmin} />
                {/* CXP */}
                <Route exact path ="/MenuCXP" component={BotonesCXP} />
                <Route exact path ="/EstadoCuentaCXP" component={EstadoCuentaCXP} />
                <Route exact path ="/ListadoComprasCXP" component={ListadoComprasCXP} />
                <Route exact path ="/AbonosProveedoresCreate" component={AbonosProveedoresCreate} />
                <Route exact path ="/ListadoAbonosProveedores" component={ListadoAbonosProveedores} />
                <Route exact path ="/ListadoDevolucionesProveedoresAdmin" component={ListadoDevolucionesProveedoresAdmin} />
            </Switch>
            </BrowserRouter>
            </AuthContextProvider>

    )
}

export default Routes
