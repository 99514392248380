import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import {
  Col,
  Row,
  Form,
  Button,
  FormGroup,
  Label,
  Input,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import axios from "axios";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";

import { v4 as uuidv4 } from "uuid";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(1),
  },
}));

function DevolucionesPedidoCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_SURTIDOS = process.env.REACT_APP_URL_SURTIDOS;
  const URL_DEVOLUCIONES = process.env.REACT_APP_URL_DEVOLUCIONES;
  const URL_ARTICULOS_PEDIDO = process.env.REACT_APP_URL_ARTICULOS_PEDIDO;
  const URL_INVENTARIOS = process.env.REACT_APP_URL_INVENTARIOS;
  const URL_ARTICULOS_DEVOLUCIONES =
    process.env.REACT_APP_URL_ARTICULOS_DEVOLUCIONES;

  const URL_DEVOLUCIONES_ACUMULADOS =
    process.env.REACT_APP_URL_DEVOLUCIONES_ACUMULADOS;

  const [surtidos, setSurtidos] = useState([]);
  const [selectedSurtido, setSelectedSurtido] = useState("");

  const [cliente, setCliente] = useState("");
  const [idCliente, setIdCliente] = useState("");
  const [fecha, setFecha] = useState("");
  const [fecha_compromiso, setFechaCompromiso] = useState("");
  const [observaciones, setObservaciones] = useState("NA");
  const [total_kilos, setTotalKilos] = useState(0);
  const [total_metros, setTotalMetros] = useState(0);

  const [pedido, setPedido] = useState("");

  const [articulosPedido, setArticulosPedido] = useState([]);
  const [articulosDevolucion, setArticulosDevolucion] = useState([]);
  const [fecha_surtido, setFechaSurtido] = useState("");
  const [clientePedido, setClientePedido] = useState("");
  const [total_kilos_pedido, setTotalKilosPedido] = useState(0);
  const [total_metros_pedido, setTotalMetrosPedido] = useState(0);
  const [total_general_pedido, setTotalGeneralPedido] = useState(0);

  const [idsInventario, setIdsInventario] = useState([]);
  const [cantidadAgrupado, setCantidadAgrupado] = useState([]);

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  const classes = useStyles();
  const [inputFields, setInputFields] = useState([
    {
      id: uuidv4(),
      idInventario: "",
      articulos: "",
      idArticulo: "",
      unidad: "",
      color: "",
      cantidad: 0,
      clave: "",
      articulosPedido: "",
    },
  ]);

  const [validaBoton, setValidaBoton] = useState(true);

  useEffect(() => {
    axios
      .get(URL_SURTIDOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allSurtidos = res.data;
        setSurtidos(allSurtidos);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function calculaDevolucion() {
    const newArticulosPedido = articulosPedido.map((AP) => {
      cantidadAgrupado.map((c) => {
        if (
          AP.articulos[0]._id == c.idArticulo &&
          AP.colores[0]._id == c.color
        ) {
          AP.surtido = parseFloat(AP.surtido) - parseFloat(c.cantidad);
          AP.pendiente_surtir =
            parseFloat(AP.pendiente_surtir) + parseFloat(c.cantidad);
        }
        return AP;
      });
    });
    setArticulosPedido(newArticulosPedido);
  }

  const saveDevolucion = async (event) => {
    event.preventDefault();
    setValidaBoton(false);
    toggleProgreso();

    calculaDevolucion();

    let totalRollos = inputFields.length + cantidadAgrupado.length;
    // + articulosPedido.length
    try {
      await axios
        .post(
          URL_DEVOLUCIONES,
          {
            fecha,
            surtidos: selectedSurtido,
            user: user.id,
            observaciones,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then((data) => {
          inputFields.map((a) => {
            if (a.articulos != "") {
              axios
                .patch(
                  `${URL_INVENTARIOS}/devolucion/${a._id}`,
                  {
                    devoluciones: data.data._id,
                    vendido: "No",
                    articulos:a.idArticulo,
                    colores:a.color,
                    clave:a.clave
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                )
                .then(() => {
                  totalRollos = totalRollos - 1;
                  if (totalRollos == 0) {
                    axios
                      .post(
                        URL_LOGS,
                        {
                          tipo: "Crear Devolucion",
                          detalle: `Surtido ${selectedSurtido}`,
                          user: user.id,
                        },
                        {
                          headers: {
                            Authorization: `Bearer: ${localStorage.getItem(
                              "app_token"
                            )}`,
                          },
                        }
                      )
                      .then(() => {
                        Swal.fire("Good job!", "Creado con exito", "success");
                        setTimeout(() => {
                          window.location.reload();
                        }, 1000);
                      })
                      .catch((error) => {
                        Swal.fire({
                          icon: "error",
                          title: "Oops...",
                          text: "Something went wrong! 1",
                          footer: `${error.response.data}`,
                        });
                        console.log(error);
                        setValidaBoton(true);
                      });
                  }
                });
            } else {
              totalRollos = totalRollos - 1;
              if (totalRollos == 0) {
                axios
                  .post(
                    URL_LOGS,
                    {
                      tipo: "Crear Devolucion",
                      detalle: `Surtido ${selectedSurtido}`,
                      user: user.id,
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  )
                  .then(() => {
                    Swal.fire("Good job!", "Creado con exito", "success");
                    setTimeout(() => {
                      window.location.reload();
                    }, 1000);
                  })
                  .catch((error) => {
                    Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: "Something went wrong! 2",
                      footer: `${error.response.data}`,
                    });
                    console.log(error);
                    setValidaBoton(true);
                  });
              }
            }
          });

          // Pegamos a Devoluciones Acumulado

          cantidadAgrupado.map((w) => {
            axios
              .post(
                `${URL_DEVOLUCIONES_ACUMULADOS}`,
                {
                  devoluciones: data.data._id,
                  articulos: w.idArticulo,
                  articulosPedido: w.articulosPedido,
                  colores: w.color,
                  cantidad: w.cantidad,
                  pedidos: pedido,
                  fecha,
                  clientes: idCliente,
                  surtidos: selectedSurtido,
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              )
              .then(() => {
                totalRollos = totalRollos - 1;
                if (totalRollos == 0) {
                  axios
                    .post(
                      URL_LOGS,
                      {
                        tipo: "Crear Devolucion",
                        detalle: `Surtido ${selectedSurtido}`,
                        user: user.id,
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem(
                            "app_token"
                          )}`,
                        },
                      }
                    )
                    .then(() => {
                      Swal.fire("Good job!", "Creado con exito", "success");
                      setTimeout(() => {
                        window.location.reload();
                      }, 1000);
                    })
                    .catch((error) => {
                      Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong! 3",
                        footer: `${error.response.data}`,
                      });
                      console.log(error);
                      setValidaBoton(true);
                    });
                }
              })
              .catch((error) => {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong! 4",
                  footer: `${error.response.data}`,
                });
                console.log(error);
              });
          });

          // Terminamos de post a devoluciones acumulado
        });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong! 5",
        // footer: `${error.response.data}`,
      });
      console.log(error);
    }
  };
  
  function jalaSurtido(e) {
    setSelectedSurtido(e);
  console.log(e)
    axios
      .get(`${URL_INVENTARIOS}/surtidosDevolucion/${e}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allArticulosDevolucion = res.data;
        setArticulosDevolucion(allArticulosDevolucion);
        let result = allArticulosDevolucion.map(({ _id }) => _id);
        setIdsInventario(result);
        setInputFields(allArticulosDevolucion);
        let agrupado = allArticulosDevolucion.reduce(function (groups, item) {
          const val = item["idArticulo"] + item["color"];
          groups[val] = groups[val] || {
            idArticulo: item.idArticulo,
            color: item.color,
            cantidad: 0,
          };
          groups[val].cantidad += item.cantidad;
          groups[val].idArticulo = item.idArticulo;
          groups[val].color = item.color;
          groups[val].articulosPedido = item.articulosPedido;
          return groups;
        }, []);

        let CA = Object.values(agrupado);
        setCantidadAgrupado(CA);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${URL_ARTICULOS_PEDIDO}/pedidosDevolucion/${e}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allArticulosPedido = res.data;
        setArticulosPedido(allArticulosPedido);
      })
      .catch((err) => {
        console.log(err);
      });

    surtidos.map((a) => {
      if (a._id == e) {
        setFechaSurtido(a.fecha);
        setTotalKilosPedido(a.total_kilos);
        setTotalMetrosPedido(a.total_metros);
        setTotalGeneralPedido(a.total_general);
        setCliente(a.pedidos[0].clientes[0].razon_social);
        setIdCliente(a.pedidos[0].clientes[0]._id);
        setPedido(a.pedidos[0]._id);
      }
    });
  }

  return (
    <>
      <Header />
      <br />
      <br />
      {user.pedidos_create ? (
        <div className="card container col-10">
          <h3 align="center">Devolucion Surtido Completo</h3>
          <Form>
            <Row>
              <Col md={3}>
                <Label className="mr-sm-2">Surtido</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedSurtido}
                  required
                  onChange={(e) => jalaSurtido(e.target.value)}
                >
                  <option value="0">Selecciona un Surtido</option>
                  {surtidos
                    .sort((a, b) => (a.idSurtido < b.idSurtido ? 1 : -1))
                    .map((a) => {
                      if (a.devuelto == "No") {
                        return (
                          <option value={a._id}>
                            # {a.idSurtido}{" "}
                            {a.pedidos[0].clientes[0].nombre_comercial}
                          </option>
                        );
                      }
                    })}
                </Input>
              </Col>
              <Col md={3}>
                <Label>Fecha Surtido</Label>
                <Input
                  type="date"
                  placeholder="Fecha"
                  value={fecha_surtido}
                  required
                  disabled
                  onChange={(e) => {
                    setFechaSurtido(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Cliente</Label>
                <Input
                  type="text"
                  placeholder="Cliente"
                  value={cliente}
                  required
                  disabled
                  onChange={(e) => {
                    setCliente(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <Label>Fecha</Label>
                <Input
                  type="date"
                  placeholder="Fecha"
                  value={fecha}
                  required
                  onChange={(e) => {
                    setFecha(e.target.value);
                  }}
                />
              </Col>
              <Col md={6}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Observaciones
                </Label>
                <Input
                  type="text"
                  placeholder="Observaciones"
                  value={observaciones}
                  required
                  onChange={(e) => {
                    setObservaciones(e.target.value);
                  }}
                />
              </Col>
            </Row>
          </Form>
          <Row>
            <Col>
              {validaBoton ? (
                <Button
                  type="submit"
                  className="btn btn-success"
                  onClick={saveDevolucion}
                >
                  Guardar
                </Button>
              ) : (
                <Button type="submit" className="btn btn-success" disabled>
                  Guardar
                </Button>
              )}
              <Button
                href="/ListadoDevoluciones"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
          </Row>
          <br />
           <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
             <h4> Este proceso puede tardar varios minutos.
              <br />
              Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
              </div>
            </ModalBody>
          </Modal>
        </div>
      ) : undefined}
    </>
  );
}

export default DevolucionesPedidoCreate;
