import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import PasswordCliente from "./PasswordCliente";
import Baja from "../../Baja";
import ClientesInfo from "./ClientesInfo";
import { TableHeader, Pagination, Search } from "../../../DataTable"
import useFullPageLoader from "../../../hooks/useFullPageLoader"

function ListadoClientes() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_GRUPOS = process.env.REACT_APP_URL_GRUPOS;
  const URL_COLABORADORES = process.env.REACT_APP_URL_COLABORADORES;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const [clientes, setClientes] = useState([]);
  const [clienteEdit, setClienteEdit] = useState([]);
  const [grupos, setGrupos] = useState([]);
  const [selectedGrupo, setSelectedGrupo] = useState("");
  const [colaboradores, setColaboradores] = useState([]);
  const [selectedColaborador, setSelectedColaborador] = useState("");
  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");
  const [idPassword, setIdPassword] = useState("");
  const [idEditCliente, setIdEditCliente] = useState("");
  const [selectedGrupoEdit, setSelectedGrupoEdit] = useState("");
  const [nombre_comercial, setNombreComercial] = useState("");
  const [razon_social, setRazonSocial] = useState("");
  const [RFC, setRFC] = useState("");
  const [calle, setCalle] = useState("");
  const [numero_ext, setNumeroExt] = useState("");
  const [numero_int, setNumeroInt] = useState("");
  const [delegacion, setDelegacion] = useState("");
  const [estado, setEstado] = useState("");
  const [pais, setPais] = useState("");
  const [cp, setCP] = useState("");
  const [colonia, setColonia] = useState("");
  const [codigo, setCodigo] = useState("");
  const [ciudad, setCiudad] = useState("");
  const [nombre, setNombre] = useState("");
  const [apellido, setApellido] = useState("");
  const [telefono, setTelefono] = useState("");
  const [email, setEmail] = useState("");
  const [emailViejo, setEmailViejo] = useState("");
  const [idInfo, setIdInfo] = useState("");
  const [plazo, setPlazo] = useState("");
  const [selectedContacto, setSelectedContacto] = useState("");
  const [selectedContactoName, setSelectedContactoName] = useState("");
  // const [selectedCobradorEdit, setSelectedCobradorEdit] = useState("");
  // const [selectedCobradorName, setSelectedCobradorName] = useState("");
  const [observaciones, setObservaciones] = useState("");
  // const [fecha_visita, setFechaVisita] = useState("");
  const [ultimo_pago, setUltimoPago] = useState("");
  const [forma_pago, setFormaPago] = useState("");
  const [selected_grupo_name, setSelectedGrupoName] = useState("");
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = () => setModalEdit(!modalEdit);
  const [modalPassword, setModalPassword] = useState(false);
  const togglePassword = () => setModalPassword(!modalPassword);
    const [activo, setActivo] = useState('');
    const [contacto, setContacto] = useState('');
    // const [cobrador, setCobrador] = useState('');
    const [grupo, setGrupo] = useState('');
    const [selectedContactoFiltro, setSelectedContactoFiltro] = useState("");

    //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50

  useEffect(() => {
    axios
      .get(URL_CLIENTES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allClientes = response.data;
    // Array para pagination
    let arrayTabla = allClientes
    .sort((a, b) => (a.nombre_comercial > b.nombre_comercial ? 1 : -1))
    .map((a) => {
      return {
        _id:a._id,
        activo: a.is_active,
        // nombre: a.nombre,
        // apellido: a.apellido,
        telefono: a.telefono,
        email: a.email,
        calle: a.calle,
        numero_ext: a.numero_ext,
        numero_int: a.numero_int,
        pais: a.pais,
        estado: a.estado,
        ciudad: a.ciudad,
        delegacion: a.delegacion,
        colonia: a.colonia,
        codigo: a.codigo,
        cp: a.cp,
        nombre_comercial: a.nombre_comercial,
        razon_social: a.razon_social,
        RFC: a.RFC,
        grupo: a.grupos[0].name,
        idGrupo: a.grupos[0]._id,
        // cobrador: a.colaboradores[0].nombre + " " + a.colaboradores[0].apellido,
        // idCobrador: a.colaboradores[0]._id,
        contacto: a.contacto[0].nombre + " " + a.contacto[0].apellido,
        idContacto: a.contacto[0]._id,
        observaciones: a.observaciones,
        // fecha_visita: a.fecha_visita,
        ultimo_pago: a.ultimo_pago,
        forma_pago: a.forma_pago,
        plazo: a.plazo
      };
    }).filter(function (el) {
      return el != null;
    });

    let dataFinal = Object.values(arrayTabla);

    setClientes(allClientes);
    setComments(dataFinal)
    //

      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_GRUPOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allGrupos = response.data;
        setGrupos(allGrupos);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_COLABORADORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allColaboradores = response.data;
        setColaboradores(allColaboradores);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const ListaClientes = clientes.sort((a, b) =>
    a.nombre_comercial > b.nombre_comercial ? 1 : -1
    
  );

  function PDFTabla() {
    const data = ListaClientes.map((a) => {
      if (
        (grupo == 0 || grupo == a.grupos[0]._id) &&
        // (cobrador == 0 ||
        //   cobrador == a.colaboradores[0]._id) &&
          (contacto == 0 ||
            contacto == a.contacto[0]._id) &&
          (activo == 0 ||
            activo == a.is_active)
      ) {
        return [
          a.nombre_comercial,
          a.telefono,
          a.email,
          a.grupos[0].name,
          [a.contacto[0].nombre + " " + a.contacto[0].apellido],
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 60, 25);
    doc.text(`Listado Clientes`, 20, 40);
    doc.autoTable({
      head: [["Nombre", "Telefono", "Mail", "Zona", "Contacto"]],
      body: dataPDFLimpia,
      startY: 45,
    });
    doc.save(`Clientes.pdf`);
  }

  function excel() {
    const dataExcel = ListaClientes.map((a) => {
      if (
        (grupo == 0 || grupo == a.grupos[0]._id) &&
        // (cobrador == 0 ||
        //   cobrador == a.colaboradores[0]._id) &&
          (contacto == 0 ||
            contacto == a.contacto[0]._id) &&
          (activo == 0 ||
            activo == a.is_active)
      ) {
        return {
          Nombre: a.nombre_comercial,
          Telefono: a.telefono,
          Mail: a.email,
          Zona: a.grupos[0].name,
          Contacto: [
            a.contacto[0].nombre + " " + a.contacto[0].apellido,
          ],
          // Cobrador: [
          //   a.colaboradores[0].nombre + " " + a.colaboradores[0].apellido,
          // ],
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoClientes";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoClientes",
        sheetFilter: ["Nombre", "Telefono", "Mail", "Zona", "Contacto"],
        sheetHeader: ["Nombre", "Telefono", "Mail", "Zona", "Contacto"],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    const data = ListaClientes.map((a) => {
      if (
        (grupo == 0 || grupo == a.grupos[0]._id) &&
        // (cobrador == 0 ||
        //   cobrador == a.colaboradores[0]._id) &&
          (contacto == 0 ||
            contacto == a.contacto[0]._id) &&
          (activo == 0 ||
            activo == a.is_active)
      ) {
        return [
          a.nombre_comercial,
          a.telefono,
          a.email,
          a.grupos[0].name,
          [a.contacto[0].nombre + " " + a.contacto[0].apellido],
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 60, 25);
    doc.text(`Listado Clientes`, 20, 40);
    doc.autoTable({
      head: [["Nombre", "Telefono", "Mail", "Zona", "Contacto"]],
      body: dataPDFLimpia,
      startY: 45,
    });
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Listado Clientes",
          email: mailTo,
          fileName: "ListadoClientes.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de Clientes.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  function jalaInfo(idEdit, 
    // nombre, apellido, 
    telefono, email, calle, numero_ext, 
    numero_int, pais, estado, ciudad, delegacion, colonia, cp, nombre_comercial, 
    razon_social, RFC, gruposName, gruposId,
    contactoNombre, contactoId, observaciones, ultimo_pago, forma_pago, codigo,
    plazo ) {
        // setNombre(nombre);
        // setApellido(apellido);
        setTelefono(telefono);
        setEmail(email);
        setEmailViejo(email);
        setCalle(calle);
        setNumeroExt(numero_ext);
        setNumeroInt(numero_int);
        setPais(pais);
        setEstado(estado);
        setCiudad(ciudad);
        setDelegacion(delegacion);
        setColonia(colonia);
        setCP(cp);
        setNombreComercial(nombre_comercial);
        setRazonSocial(razon_social);
        setRFC(RFC);
        setSelectedGrupoName(gruposName);
        setSelectedGrupoEdit(gruposId);
        // setSelectedCobradorName(cobrador);
        // setSelectedCobradorEdit(idCobrador);
        setSelectedContactoName(contactoNombre);
        setSelectedContacto(contactoId);
        setObservaciones(observaciones);
        // setFechaVisita(fecha_visita);
        setUltimoPago(ultimo_pago);
        setFormaPago(forma_pago);
        setCodigo(codigo)
        setIdEditCliente(idEdit);
        setPlazo(plazo)
        toggleEdit();
  }

  function Info(id) {
    setIdInfo(id);
    toggle();
  }

  function editClientes(event) {
    event.preventDefault();
    const URL_CLIENTES_EDIT = `${process.env.REACT_APP_URL_CLIENTES}/${idEditCliente}`;
    // const URL_USERS_DON = `${process.env.REACT_APP_URL_USERS}/email/${emailViejo}`;
    // axios
    //   .get(URL_USERS_DON, {
    //     headers: {
    //       Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
    //     },
    //   })
    //   .then((response) => {
        // let idEditClienteUsuario = response.data[0]._id;
        // const URL_SIGNUP_DON = `${process.env.REACT_APP_URL_USERS}/${idEditClienteUsuario}`;
        axios
          .all([
            axios.patch(
              URL_CLIENTES_EDIT,
              {
                nombre_comercial,
                razon_social,
                RFC,
                calle,
                numero_ext,
                numero_int,
                delegacion,
                estado,
                pais,
                cp,
                colonia,
                ciudad,
                // nombre,
                // apellido,
                telefono,
                email,
                grupos: selectedGrupoEdit,
                // colaboradores: selectedCobradorEdit,
                contacto: selectedContacto,
                observaciones,
                // fecha_visita,
                ultimo_pago,
                forma_pago,
                codigo,
                plazo,
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                },
              }
            ),
          ])
          .then(() => {
            axios.post(
              URL_LOGS,
              {
                tipo: "Editar Cliente",
                detalle: `${nombre_comercial}`,
                user: user.id,
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                },
              }
            );
            // axios.patch(
            //   URL_SIGNUP_DON,
            //   {
            //     nombre: nombre,
            //     apellido: apellido,
            //     email: email,
            //   },
            //   {
            //     headers: {
            //       Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            //     },
            //   }
            // );
            toggleEdit();
            Swal.fire("Good job!", "Actualizado con exito", "success");
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
          });
      // })
      // .catch((err) => {
      //   console.log(err);
      // });
  }

  function jalaInfoPassword(idCliente) {
      const URL_USERS_CLIENTE = `${process.env.REACT_APP_URL_USERS}/cliente/${idCliente}`;
    axios
      .get(URL_USERS_CLIENTE, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let idEditClienteUsuario = response.data[0]._id;
        setIdPassword(idEditClienteUsuario);
        togglePassword();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const headers = [
    { name: "Codigo", field: "codigo", sortable: true },
    { name: "Nombre Comercial", field: "nombre_comercial", sortable: true },
    { name: "Telefono", field: "telefono", sortable: false },
    { name: "Email", field: "email", sortable: true },
    { name: "Zona", field: "grupo", sortable: true },
    { name: "Contacto", field: "contacto", sortable: true },
    { name: "Acciones", field: "acciones", sortable: false },
];

  const commentsData = useMemo(() => {
    let computedComments = comments

    if (search) {
        computedComments = computedComments.filter(
            comment =>
                comment.nombre_comercial.toLowerCase().includes(search.toLowerCase()) ||
                // comment.apellido.toLowerCase().includes(search.toLowerCase()) ||
                comment.email.toLowerCase().includes(search.toLowerCase()) ||
                comment.grupo.toLowerCase().includes(search.toLowerCase()) ||
                comment.codigo.toLowerCase().includes(search.toLowerCase()) ||
                comment.contacto.toLowerCase().includes(search.toLowerCase())
        );
    }
    if(grupo){
      computedComments = computedComments.filter((e)=>
      e.idGrupo.includes(grupo)
      ) 
    } 
    if(contacto){
      computedComments = computedComments.filter((e)=>
      e.idContacto.includes(contacto)
      ) 
    } 
    // if(cobrador){
    //   computedComments = computedComments.filter((e)=>
    //   e.idCobrador.includes(cobrador)
    //   ) 
    // } 
    if(activo){
      computedComments = computedComments.filter((e)=>
      e.activo.includes(activo)
      ) 
    }


    setTotalItems(computedComments.length);

    //Sorting comments
    if (sorting.field) {
        const reversed = sorting.order === "asc" ? 1 : -1;
        computedComments = computedComments.sort(
            (a, b) =>
                reversed * a[sorting.field].localeCompare(b[sorting.field])
        );
    }

    //Current Page slice
    return computedComments.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
}, [comments, currentPage, search, sorting, contacto, grupo, activo]);


  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_clientes ? (
        <div className="card col-12">
          <Row>
            <Col md={8}>
              {user.clientes_create ? (
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  href="/ClientesCreate"
                >
                  Nuevo Cliente
                </Button>
              ) : (
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  disabled
                >
                  Nuevo Cliente
                </Button>
              )}
              <Button
                size="sm"
                href="/MenuVentas"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>Enviar Listado Clientes</h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={enviaMail}>
                Enviar
              </Button>
            </ModalFooter>
          </Modal>
          <h3 align="center">Clientes</h3>
          <div className="row">
                        <div className="col-md-8">
                            <Pagination
                                total={totalItems}
                                itemsPerPage={ITEMS_PER_PAGE}
                                currentPage={currentPage}
                                onPageChange={page => setCurrentPage(page)}
                            />
                        </div>
                        <div className="col-md-4 d-flex flex-row-reverse">
                            <Search
                                onSearch={value => {
                                    setSearch(value);
                                    setCurrentPage(1);
                                }}
                            />
                        </div>
                    </div>
          <Row>
            <Col md={2}>
              <Label>Zonas</Label>
              <Input
                bsSize="sm"
                type="select"
                value={grupo}
                onChange={(e) => {
                  setGrupo(e.target.value)
                  setCurrentPage(1)
                }}
              >
                <option value="">Selecciona</option>
                {grupos
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((a) => {
                    return <option value={a._id}>{a.name}</option>;
                  })}
              </Input>
            </Col>
            {/* <Col md={2}>
              <Label>Cobrador</Label>
              <Input
                 bsSize="sm"
                 type="select"
                 value={cobrador}
                 onChange={(e) => {
                   setCobrador(e.target.value)
                   setCurrentPage(1)
                 }}
              >
                <option value="0">Selecciona</option>
                {colaboradores
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((a) => {
                    if (a.cobrador == "Si") {
                      return (
                        <option value={a._id}>
                          {a.nombre} {a.apellido}
                        </option>
                      );
                    }
                  })}
              </Input>
            </Col> */}
            <Col md={2}>
              <Label>Contacto</Label>
              <Input
                bsSize="sm"
                type="select"
                value={contacto}
                onChange={(e) => {
                  setContacto(e.target.value)
                  setCurrentPage(1)
                }}
              >
                <option value="0">Selecciona</option>
                {colaboradores
                  .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                  .map((a) => {
                    // if (a.cobrador == "Si") {
                      return (
                        <option value={a._id}>
                          {a.nombre} {a.apellido}
                        </option>
                      );
                    // }
                  })}
              </Input>
            </Col>
            <Col md={2}>
              <Label>Activo</Label>
              <Input
                bsSize="sm"
                type="select"
                value={activo}
                required
                onChange={(e) => {
                  setActivo(e.target.value)
                  setCurrentPage(1)
                }}
              >
                <option value="">Selecciona</option>
                <option value="Si">Activos</option>
                <option value="No">Inactivos</option>
              </Input>
            </Col>
          </Row>
            <br />
          <Table size="sm" striped borderless className="table-responsive-xl">
                <TableHeader
                            headers={headers}
                            onSorting={(field, order) =>
                                setSorting({ field, order })
                            }
                        />
            <tbody>
              {commentsData.map((p) => {
                {
                  return (
                    <tr>
                      <td>{p.codigo}</td>
                      <td>
                        {p.nombre_comercial}
                      </td>
                      <td>{p.telefono}</td>
                      <td>{p.email}</td>
                      <td>{p.grupo}</td>
                      {/* <td>
                        {p.cobrador}
                      </td> */}
                      <td>
                        {p.contacto}
                      </td>
                      <td>
                        {user.clientes_create ? (
                          <div>
                            <Button
                              color="dany"
                              size="sm"
                              onClick={(e) => Info(p._id)}
                              id="Detalle"
                            >
                              <i class="fas fa-file-alt"></i>
                            </Button>
                            <Button
                              color="info"
                              id="Editar"
                              size="sm"
                              onClick={(e) => jalaInfo(p._id, 
                                // p.nombre, p.apellido, 
                                p.telefono, p.email, p.calle, p.numero_ext, p.numero_int,
                                p.pais, p.estado, p.ciudad, p.delegacion, p.colonia, p.cp, p.nombre_comercial, p.razon_social, p.RFC, p.grupo,
                                p.idGrupo, p.contacto,
                                p.idContacto, p.observaciones, p.ultimo_pago, p.forma_pago, p.codigo, p.plazo)}
                            >
                              <i class="far fa-edit"></i>
                            </Button>
                            {/* <Button
                              color="success"
                              id="Password"
                              size="sm"
                              onClick={(e) => jalaInfoPassword(p._id)}
                            >
                              <i class="fas fa-user-lock"></i>
                            </Button> */}
                            <Baja
                              idStatus={p._id}
                              is_active={p.activo}
                              URL_BAJA={process.env.REACT_APP_URL_CLIENTES}
                            />
                          </div>
                        ) : (
                          <div>
                            <Button color="info" id="Editar" size="sm" disabled>
                              <i class="far fa-edit"></i>
                            </Button>
                            <Button
                              color="success"
                              id="Password"
                              size="sm"
                              disabled
                            >
                              <i class="fas fa-user-lock"></i>
                            </Button>
                          </div>
                        )}
                      </td>
                    </tr>
                  );
                }
              })}
            </tbody>
          </Table>
          <div className="col-md-6">
                            <Pagination
                                total={totalItems}
                                itemsPerPage={ITEMS_PER_PAGE}
                                currentPage={currentPage}
                                onPageChange={page => setCurrentPage(page)}
                            />
                        </div>
        </div>
      ) : undefined}
      <br />

      <Modal size="sm" isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          <h4>Informacion del Cliente</h4>{" "}
        </ModalHeader>
        <ModalBody>
          <ClientesInfo idCliente={idInfo} />
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={toggle}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal size="sm" isOpen={modalPassword} toggle={togglePassword}>
        <ModalHeader toggle={togglePassword}>
          <h4>Cambio de contraseña</h4>
        </ModalHeader>
        <ModalBody>
          <PasswordCliente idCliente={idPassword} />
        </ModalBody>
      </Modal>

      <Modal size="lg" isOpen={modalEdit} toggle={toggleEdit}>
        <ModalHeader toggle={toggleEdit}>
          <h4>
            Editar cliente {nombre_comercial}
          </h4>
        </ModalHeader>
        <ModalBody>
        <Row>
              {/* <Col md={3}>
                <Label>Nombre</Label>
                <Input
                  type="text"
                  placeholder="Nombre"
                  value={nombre}
                  required
                  onChange={(e) => {
                    setNombre(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Apellido</Label>
                <Input
                  type="text"
                  placeholder="Apellido"
                  value={apellido}
                  required
                  onChange={(e) => {
                    setApellido(e.target.value);
                  }}
                />
              </Col> */}
               <Col md={3}>
                <Label>Codigo</Label>
                <Input
                  type="text"
                  placeholder="Codigo"
                  value={codigo}
                  required
                  onChange={(e) => {
                    setCodigo(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Nombre Comercial</Label>
                <Input
                  type="text"
                  placeholder="Nombre Comercial"
                  value={nombre_comercial}
                  required
                  onChange={(e) => {
                    setNombreComercial(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Razon Social</Label>
                <Input
                  type="text"
                  placeholder="Razon Social"
                  value={razon_social}
                  required
                  onChange={(e) => {
                    setRazonSocial(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>RFC</Label>
                <Input
                  type="text"
                  placeholder="RFC"
                  value={RFC}
                  required
                  onChange={(e) => {
                    setRFC(e.target.value);
                  }}
                />
              </Col>
              
              
            </Row>

            <Row>
              <Col md={8}>
                <Label>Calle</Label>
                <Input
                  type="text"
                  placeholder="Calle"
                  value={calle}
                  required
                  onChange={(e) => {
                    setCalle(e.target.value);
                  }}
                />
              </Col>
              <Col md={2}>
                <Label>No. Ext</Label>
                <Input
                  type="text"
                  placeholder="No. Ext"
                  value={numero_ext}
                  required
                  onChange={(e) => {
                    setNumeroExt(e.target.value);
                  }}
                />
              </Col>
              <Col md={2}>
                <Label>No. Int</Label>
                <Input
                  type="text"
                  placeholder="No. Int"
                  value={numero_int}
                  required
                  onChange={(e) => {
                    setNumeroInt(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <Label>Pais</Label>
                <Input
                  type="text"
                  placeholder="Pais"
                  value={pais}
                  required
                  onChange={(e) => {
                    setPais(e.target.value);
                  }}
                />
              </Col>
              <Col md={4}>
                <Label>Estado</Label>
                <Input
                  type="text"
                  placeholder="Estado"
                  value={estado}
                  required
                  onChange={(e) => {
                    setEstado(e.target.value);
                  }}
                />
              </Col>
              <Col md={4}>
                <Label>Ciudad</Label>
                <Input
                  type="text"
                  placeholder="Ciudad"
                  value={ciudad}
                  required
                  onChange={(e) => {
                    setCiudad(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <Label>Delegacion</Label>
                <Input
                  type="text"
                  placeholder="Delegacion o Municipio"
                  value={delegacion}
                  required
                  onChange={(e) => {
                    setDelegacion(e.target.value);
                  }}
                />
              </Col>
              <Col md={4}>
                <Label>Colonia</Label>
                <Input
                  type="text"
                  placeholder="Colonia"
                  value={colonia}
                  required
                  onChange={(e) => {
                    setColonia(e.target.value);
                  }}
                />
              </Col>
              <Col md={4}>
                <Label>CP</Label>
                <Input
                  type="text"
                  placeholder="CP"
                  value={cp}
                  required
                  onChange={(e) => {
                    setCP(e.target.value);
                  }}
                />
              </Col>
            </Row>

            <Row>
            <Col md={3}>
                <Label>Telefono</Label>
                <Input
                  type="text"
                  placeholder="Telefono"
                  value={telefono}
                  required
                  onChange={(e) => {
                    setTelefono(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Email</Label>
                <Input
                  type="email"
                  placeholder="Email"
                  value={email}
                  required
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </Col>
              
             
            </Row>
            <Row>
              <Col md={3}>
                <Label className="mr-sm-2">Zona</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedGrupo}
                  required
                  onChange={(e) => {
                    setSelectedGrupo(e.target.value);
                  }}
                >
                  <option value="0">Selecciona una Zona</option>
                  {grupos
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
              </Col>

              <Col md={3}>
                <Label className="mr-sm-2">Contacto Interno</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedContacto}
                  required
                  onChange={(e) => {
                    setSelectedContacto(e.target.value);
                  }}
                >
                  <option value="0">Selecciona un Contacto</option>
                  {colaboradores
                    .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                    .map((a) => {
                      return (
                        <option value={a._id}>
                          {a.nombre} {a.apellido}
                        </option>
                      );
                    })}
                </Input>
              </Col>
              {/* <Col md={3}>
                <Label className="mr-sm-2">Cobrador</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedColaborador}
                  required
                  onChange={(e) => {
                    setSelectedColaborador(e.target.value);
                  }}
                >
                  <option value="0">Selecciona un Cobrador</option>
                  {colaboradores
                    .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                    .map((a) => {
                      if (a.cobrador == "Si") {
                        return (
                          <option value={a._id}>
                            {a.nombre} {a.apellido}
                          </option>
                        );
                      }
                    })}
                </Input>
              </Col> */}
              {/* <Col md={3}>
                <Label>Password</Label>
                <Input type="text" placeholder={password} disabled />
              </Col> */}
               <Col md={3}>
                <Label>Forma de Pago</Label>
                <Input
                  type="select"
                  placeholder="Forma de Pago"
                  value={forma_pago}
                  required
                  onChange={(e) => {
                    setFormaPago(e.target.value);
                  }}
                >
                  <option value="0">Forma de Pago</option>
                  <option value="Efectivo">Efectivo</option>
                  <option value="Deposito">Deposito</option>
                  <option value="Cheque">Cheque</option>
                  <option value="PorDefinir">Por Definir</option>
                </Input>
              </Col>
              <Col md={3}>
                <Label>Plazo</Label>
                <Input
                  type="select"
                  placeholder="Plazo"
                  value={plazo}
                  required
                  onChange={(e) => {
                    setPlazo(e.target.value);
                  }}
                >
                  <option value="">Plazo</option>
                  <option value="0">0</option>
                  <option value="30">30</option>
                  <option value="60">60</option>
                  <option value="90">90</option>
                  <option value="120">120</option>
                  <option value="150">150</option>
                </Input>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Observaciones
                </Label>
                <Input
                  type="textarea"
                  placeholder="Observaciones"
                  value={observaciones}
                  required
                  onChange={(e) => {
                    setObservaciones(e.target.value);
                  }}
                />
              </Col>
             
            </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={editClientes}>
            Guardar
          </Button>
        </ModalFooter>
      </Modal>
      {loader}
    </>
  );
}

export default ListadoClientes;
