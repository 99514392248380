import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import axios from "axios";
import Header from "../../layout/Header/Header";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_dataviz from "@amcharts/amcharts4/themes/dataviz";
import am4themes_material from "@amcharts/amcharts4/themes/material";
import am4themes_kelly from "@amcharts/amcharts4/themes/kelly";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Form,
} from "reactstrap";
import moment from 'moment';
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";

// am4core.useTheme(am4themes_dataviz);
am4core.useTheme(am4themes_animated);
// am4core.useTheme(am4themes_material);

function GraficaArticulos() {
  let hoy = new Date()
  let inicio = new Date()
  inicio.setDate(inicio.getDate()-30);
 
  let endDate   = moment(hoy).format("YYYY-MM-DD"); 
  let startDate   = moment(inicio).format("YYYY-MM-DD");

  const { user } = useContext(AuthContext);
  const URL_INVENTARIOS = process.env.REACT_APP_URL_INVENTARIOS;
  const [surtidos, setSurtidos] = useState([]);
  const [compras, setCompras] = useState([]);
  const [devoluciones, setDevoluciones] = useState([]);

  const [selectedFechaInicio, setSelectedFechaInicio] = useState(startDate);
  const [selectedFechaFin, setSelectedFechaFin] = useState(endDate);

  function jalaFechas (){

    const Toast = Swal.mixin({
      toast: true,
      position: 'center',
      showConfirmButton: false,
      timer: 5000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })
    
    Toast.fire({
      icon: 'success',
      title: 'Danos unos segundos....'
    })
    axios
    .get(`${URL_INVENTARIOS}GraficaArticulosVentas/${selectedFechaInicio}/${selectedFechaFin}`, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then((response) => {
      let allSurtidos = response.data;
      setSurtidos(allSurtidos);
    })
    .catch((err) => {
      console.log(err);
    });

  axios
    .get(`${URL_INVENTARIOS}GraficaArticulosCompras/${selectedFechaInicio}/${selectedFechaFin}`, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then((response) => {
      let allCompras = response.data;
      setCompras(allCompras);
    })
    .catch((err) => {
      console.log(err);
    });

  axios
    .get(`${URL_INVENTARIOS}GraficaArticulosDevoluciones/${selectedFechaInicio}/${selectedFechaFin}`, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then((response) => {
      let allDevoluciones = response.data;
      setDevoluciones(allDevoluciones);
    })
    .catch((err) => {
      console.log(err);
    });
  }

  useEffect(() => {
    jalaFechas()
  }, []);

  //Array Surtidos
  const arraySurtidos = surtidos
    .map((n) => {
      return {
        Articulo: n.nombre,
        idArticulo: n.idArticulo,
        Venta: n.cantidad,
        Devolucion: 0,
        Compra: 0,
      };
    })
    .filter(function (el) {
      return el != null;
    });

  const groupBySurtidos = function (miarray, prop) {
    return miarray.reduce(function (groups, item) {
      const val = item[prop];
      groups[val] = groups[val] || {
        Articulo: item.Articulo,
        Venta: 0,
      };
      groups[val].Venta += item.Venta;
      groups[val].Articulo = item.Articulo;
      groups[val].idArticulo = item.idArticulo;
      groups[val].Devolucion = item.Devolucion;
      groups[val].Compra = item.Compra;
      return groups;
    }, []);
  };

  const SurtidosAgrupado = groupBySurtidos(arraySurtidos, "idArticulo");

  const dataFinalSurtidos = Object.values(SurtidosAgrupado);

  //Array Compras
  const arrayCompras = compras
    .map((n) => {
      return {
        Articulo: n.nombre,
        idArticulo: n.idArticulo,
        Compra: n.cantidad,
        Venta: 0,
        Devolucion: 0,
      };
    })
    .filter(function (el) {
      return el != null;
    });

  const groupByCompras = function (miarray, prop) {
    return miarray.reduce(function (groups, item) {
      const val = item[prop];
      groups[val] = groups[val] || {
        Articulo: item.Articulo,
        Compra: 0,
      };
      groups[val].Compra += item.Compra;
      groups[val].Articulo = item.Articulo;
      groups[val].idArticulo = item.idArticulo;
      groups[val].Devolucion = item.Devolucion;
      groups[val].Venta = item.Venta;
      return groups;
    }, []);
  };

  const ComprasAgrupado = groupByCompras(arrayCompras, "idArticulo");

  const dataFinalCompras = Object.values(ComprasAgrupado);

  //Array Devoluciones
  const arrayDevoluciones = devoluciones
    .map((n) => {
      return {
        Articulo: n.nombre,
        idArticulo: n.idArticulo,
        Devolucion: n.cantidad,
        Compra: 0,
        Venta: 0,
      };
    })
    .filter(function (el) {
      return el != null;
    });

  const groupByDevoluciones = function (miarray, prop) {
    return miarray.reduce(function (groups, item) {
      const val = item[prop];
      groups[val] = groups[val] || {
        Articulo: item.Articulo,
        Devolucion: 0,
      };
      groups[val].Devolucion += item.Devolucion;
      groups[val].Articulo = item.Articulo;
      groups[val].idArticulo = item.idArticulo;
      groups[val].Compra = item.Compra;
      groups[val].Venta = item.Venta;
      return groups;
    }, []);
  };

  const DevolucionesAgrupado = groupByDevoluciones(
    arrayDevoluciones,
    "idArticulo"
  );

  const dataFinalDevoluciones = Object.values(DevolucionesAgrupado);

  let data = dataFinalSurtidos.concat(dataFinalCompras, dataFinalDevoluciones);

  const groupByGeneral = function (miarray, prop) {
    return miarray.reduce(function (groups, item) {
      const val = item[prop];
      groups[val] = groups[val] || {
        Articulo: item.Articulo,
        Compra: 0,
        Venta: 0,
        Devolucion: 0,
      };
      groups[val].Devolucion += item.Devolucion;
      groups[val].Articulo = item.Articulo;
      groups[val].idArticulo = item.idArticulo;
      groups[val].Compra += item.Compra;
      groups[val].Venta += item.Venta;
      return groups;
    }, []);
  };

  const dataAgrupado = groupByGeneral(data, "idArticulo");

  const data2 = Object.values(dataAgrupado);
  const dataFinal = data2.sort((a, b) => (a.Articulo > b.Articulo ? 1 : -1));

  let chart = am4core.create("chartdiv", am4charts.XYChart3D);

  chart.data = dataFinal;

  let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
  categoryAxis.dataFields.category = "Articulo";
  categoryAxis.title.text = "Articulo";

  categoryAxis.events.on("sizechanged", function (ev) {
    let axis = ev.target;
    var cellWidth = axis.pixelWidth / (axis.endIndex - axis.startIndex);
    if (cellWidth < axis.renderer.labels.template.maxWidth) {
      axis.renderer.labels.template.rotation = -45;
      axis.renderer.labels.template.horizontalCenter = "right";
      axis.renderer.labels.template.verticalCenter = "middle";
    } else {
      axis.renderer.labels.template.rotation = 0;
      axis.renderer.labels.template.horizontalCenter = "middle";
      axis.renderer.labels.template.verticalCenter = "top";
    }
  });

  let label = categoryAxis.renderer.labels.template;
  label.truncate = true;
  label.maxWidth = 100;

  // Devolucion
  let valueAxis3 = chart.yAxes.push(new am4charts.ValueAxis());
  // valueAxis3.title.text = "Devolucion";

  let series3 = chart.series.push(new am4charts.ColumnSeries3D());
  series3.columns.template.tooltipText =
    "Articulo: {categoryX}\nDevolucion: {valueY}";
  series3.dataFields.valueY = "Devolucion";
  series3.dataFields.categoryX = "Articulo";
  series3.name = "Devolucion";
  series3.clustered = false;
  // series3.columns.template.stroke = am4core.color("#911919");
  // series3.columns.template.fill = am4core.color("#911919");

  // Ventas
  let valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis());
  // valueAxis2.title.text = "Venta";

  let series2 = chart.series.push(new am4charts.ColumnSeries3D());
  series2.columns.template.tooltipText =
    "Articulo: {categoryX}\nVenta: {valueY}";
  series2.dataFields.valueY = "Venta";
  series2.dataFields.categoryX = "Articulo";
  series2.name = "Venta";
  series2.clustered = false;
  // series2.columns.template.stroke = am4core.color("#0b661a");
  // series2.columns.template.fill = am4core.color("#0b661a");

  // Compras
  let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
  // valueAxis.title.text = "Compra";

  let series = chart.series.push(new am4charts.ColumnSeries3D());
  series.columns.template.tooltipText =
    "Articulo: {categoryX}\nCompra: {valueY}";
  series.dataFields.valueY = "Compra";
  series.dataFields.categoryX = "Articulo";
  series.name = "Compra";
  series.clustered = false;
  // series.columns.template.stroke = am4core.color("#283250");
  // series.columns.template.fill = am4core.color("#283250");

  // Add legend
  chart.legend = new am4charts.Legend();
  chart.exporting.menu = new am4core.ExportMenu();
  chart.exporting.filePrefix = "myExport";
  // chart.cursor = new am4charts.XYCursor();
  chart.scrollbarX = new am4core.Scrollbar();
  chart.scrollbarY = new am4core.Scrollbar();

  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_reportes ? (
        <div className="card container col-12">
          <h3 align="center">Resumen por Articulo</h3>
      <div className="container col-12">
      <Row>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Inicio</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaInicio}
                required
                onChange={(e) => {
                  setSelectedFechaInicio(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Fin</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaFin}
                required
                onChange={(e) => {
                  setSelectedFechaFin(e.target.value);
                }}
              />
            </Col>
            <Col md={1}>
                    <Button     
                    size="sm"   
                        className="btn ir" 
                        color="info"
                        onClick={jalaFechas}
                        >
                          Actualizar 
                        </Button>
                    </Col>
          </Row>
          </div>
      <div
        id="chartdiv"
        style={{ width: "100%", height: "500px", paddingTop: "0px" }}
      ></div>
              </div>
      ) : undefined}
    </>
  );
}

export default GraficaArticulos;
