import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col, Spinner
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import BajaAltaMultiple from "../../BajaAltaMultiple";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import { v4 as uuidv4 } from "uuid";
import qrcode from "qrcode-js";

function ListadoCompras() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_ARTICULOS = process.env.REACT_APP_URL_ARTICULOS;
  const URL_TIPOS_TELAS = process.env.REACT_APP_URL_TIPOS_TELAS;
  const URL_COLORES = process.env.REACT_APP_URL_COLORES;
  const URL_ORDENES_COMPRA = process.env.REACT_APP_URL_ORDENES_COMPRA;
  const URL_COMPRAS = process.env.REACT_APP_URL_COMPRAS;
  const URL_ARTICULOS_OC = process.env.REACT_APP_URL_ARTICULOS_OC;
  const URL_INVENTARIOS = process.env.REACT_APP_URL_INVENTARIOS;
  const URL_PROVEEDORES = process.env.REACT_APP_URL_PROVEEDORES;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;

  const [compras, setCompras] = useState([]);
  const [articulosOC, setArticulosOC] = useState([]);
  const [ordenesCompra, setOrdenesCompra] = useState([]);
  const [selectedOrden, setSelectedOrden] = useState("");
  const [articulos, setArticulos] = useState([]);
  const [tipos_telas, setTiposTelas] = useState([]);
  const [selectedTiposTelasFiltro, setSelectedTiposTelasFiltro] = useState("");
  const [colores, setColores] = useState([]);
  const [selectedColor, setSelectedColor] = useState("");

  const [proveedores, setProveedores] = useState([]);
  const [selectedProveedor, setSelectedProveedor] = useState("");

  const [selectedFechaInicio, setSelectedFechaInicio] = useState("");
  const [selectedFechaFin, setSelectedFechaFin] = useState("");

  const [fecha, setFecha] = useState("");
  const [factura_proveedor, setFacturaProveedor] = useState("");
  const [observaciones, setObservaciones] = useState("");
  const [pais_origen, setPaisOrigen] = useState("");
  const [numero_compra, setNumeroCompra] = useState("");
  const [rollos, setRollos] = useState("");
  const [idEdit, setIdEdit] = useState("");
  const [selectedProveedorEdit, setSelectedProveedorEdit] = useState("");

  const [articulosCompra, setArticulosCompra] = useState([]);
  const [modalEditArticulos, setModalEditArticulos] = useState(false);
  const toggleEditArticulos = () => setModalEditArticulos(!modalEditArticulos);
  const [total_general_edit, setTotalGeneralEdit] = useState(0);
  const [total_kilos_edit, setTotalKilosEdit] = useState(0);
  const [total_metros_edit, setTotalMetrosEdit] = useState(0);

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);
  const [validaBoton, setValidaBoton] = useState(true);
  const [totalCaptura, setTotalCaptura] = useState(1000000);

  const [inputFields, setInputFields] = useState([
    {
      id: uuidv4(),
      articulosOC: "",
      articulos: "",
      unidad: "",
      colores: "",
      cantidad: 0,
      clave: "0",
      captura:1000000
    },
  ]);

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = () => setModalEdit(!modalEdit);

  const [activo, setActivo] = useState("");

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  useEffect(() => {
    axios
      .get(URL_COMPRAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allCompras = response.data;
        // Array para pagination
        let arrayTabla = allCompras
          .sort((a, b) => (a.idCompras > b.idCompras ? 1 : -1))
          .map((a) => {
            return {
              activo: a.is_active,
              _id: a._id,
              numero: a.idCompras,
              fecha: a.fecha,
              observaciones: a.observaciones,
              kilos: a.total_kilos.toFixed(2),
              metros: a.total_metros.toFixed(2),
              factura_proveedor: a.factura_proveedor,
              iduser: a.user[0]._id,
              user: a.user[0].nombre + " " + a.user[0].apellido,
              pais_origen: a.pais_origen,
              idOrdenesCompra: a.ordenesCompra[0]._id,
              numeroOrden: a.ordenesCompra[0].idOrdenCompra,
              fecha_orden_compra: a.ordenesCompra[0].fecha,
              proveedores: a.ordenesCompra[0].proveedores[0].razon_social,
              idProveedores: a.ordenesCompra[0].proveedores[0]._id,
              idOrdenCompra: a.ordenesCompra[0]._id,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);
        setCompras(allCompras);
        setComments(dataFinal);
        //
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_PROVEEDORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allProveedores = response.data;
        setProveedores(allProveedores);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_ARTICULOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulos = response.data;
        setArticulos(allArticulos);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const ListaCompras = comments.sort((a, b) =>
    a.idCompras > b.idCompras ? 1 : -1
  );

  function PDFTabla() {
    const data = ListaCompras.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (activo == 0 || activo == a.is_active) &&
        (selectedProveedor == 0 || selectedProveedor == a.idProveedores)
      ) {
        return [
          a.activo,
          a.numero,
          a.fecha,
          a.proveedores,
          a.factura_proveedor,
          a.pais_origen,
          a.numeroOrden,
          new Intl.NumberFormat("en-US").format(a.kilos),
          new Intl.NumberFormat("en-US").format(a.metros),
          a.user,
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 60, 25);
    doc.text(`Listado de Compras`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Activo",
          "Numero",
          "Fecha",
          "Proveedor",
          "Factura Proveedor",
          "Pais Origen",
          "OC",
          "Kilos",
          "Metros",
          "Recibio",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
    });
    doc.save(`Compras.pdf`);
  }

  function excel() {
    const dataExcel = ListaCompras.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (activo == 0 || activo == a.is_active) &&
        (selectedProveedor == 0 || selectedProveedor == a.idProveedores)
      ) {
        return {
          Activo: a.activo,
          Numero: a.numero,
          Fecha: a.fecha,
          Proveedores: a.proveedores,
          Factura: a.factura_proveedor,
          Pais: a.pais_origen,
          OC: a.numeroOrden,
          Kilos: a.kilos,
          Metros: a.metros,
          Recibio: a.user,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoCompras";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoCompras",
        sheetFilter: [
          "Activo",
          "Numero",
          "Fecha",
          "Proveedor",
          "Factura",
          "Pais",
          "OC",
          "Kilos",
          "Metros",
          "Recibio",
        ],
        sheetHeader: [
          "Activo",
          "Numero",
          "Fecha",
          "Proveedor",
          "Factura",
          "Pais",
          "OC",
          "Kilos",
          "Metros",
          "Recibio",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    const data = ListaCompras.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (activo == 0 || activo == a.is_active) &&
        (selectedProveedor == 0 || selectedProveedor == a.idProveedores)
      ) {
        return [
          a.activo,
          a.numero,
          a.fecha,
          a.proveedores,
          a.factura_proveedor,
          a.pais_origen,
          a.numeroOrden,
          new Intl.NumberFormat("en-US").format(a.kilos),
          new Intl.NumberFormat("en-US").format(a.metros),
          a.user,
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 60, 25);
    doc.text(`Listado de Compras`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Activo",
          "Numero",
          "Fecha",
          "Proveedor",
          "Factura Proveedor",
          "Pais Origen",
          "OC",
          "Kilos",
          "Metros",
          "Recibio",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
    });
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Listado de Compras",
          email: mailTo,
          fileName: "ListadoCompras.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de Compras.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  function jalaInfo(
    fecha,
    factura_proveedor,
    observaciones,
    numero,
    pais_origen,
    idEdit
  ) {
    setFecha(fecha);
    setFacturaProveedor(factura_proveedor);
    setObservaciones(observaciones);
    setNumeroCompra(numero);
    setPaisOrigen(pais_origen);
    setIdEdit(idEdit);
    toggleEdit();
  }

  async function PDFOC(
    fecha,
    idProveedores,
    proveedores,
    kilos,
    metros,
    observaciones,
    numero,
    idPDFOC
  ) {
    await axios
      .get(`${URL_INVENTARIOS}/compras/${idPDFOC}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulosCompra = response.data;
        let arrayTabla = allArticulosCompra
        .sort((a, b) => (a.captura > b.captura ? 1 : -1))
          .map((a) => {
            return {
              id: a._id,
              articulos: a.articulos[0].codigo + " " + a.articulos[0].nombre,
              unidad: a.articulos[0].unidad,
              colores: a.colores[0].name,
              modelo: a.articulosOC[0].modelo,
              cantidad: a.cantidad,
              cantidadString: a.cantidad.toString(),
              precio: a.articulosOC[0].precio,
              idColor: a.colores[0]._id,
              // recibido: a.articulosOC[0].recibido,
              // pendiente_recibir: a.articulosOC[0].pendiente_recibir,
              clave: a.clave
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);
        let rollos = dataFinal.length

        let agrupado = dataFinal.reduce(function (groups, item) {
          const val = item["idArticulo"] + item["idColor"]
          groups[val] = groups[val] || {
            idArticulo: item.idArticulo,
            idColores: item.idColores,
            cantidad: 0,
            rollos: 0,
            cantidadString: "",
          };
          groups[val].cantidad += item.cantidad;
          groups[val].cantidadString += item.cantidadString + " / ";
          groups[val].articulos = item.articulos;
          groups[val].unidad = item.unidad;
          groups[val].colores = item.colores;
          groups[val].precio = item.precio;
          groups[val].modelo = item.modelo;
          groups[val].rollos += 1;
          return groups;
        }, []);

        let agrupadoValues = Object.values(agrupado);


        let mts = agrupadoValues.filter((e) => e.unidad == "Metros");
        let totalMts = mts.map((c) => parseFloat(c.cantidad));
        let TM = totalMts.reduce((t, total, index) => t + total, 0);
        setTotalMetrosEdit(TM);
        let KG = agrupadoValues.filter((e) => e.unidad == "Kilos");
        let totalKG = KG.map((c) => parseFloat(c.cantidad));
        let TK = totalKG.reduce((t, total, index) => t + total, 0);
        setTotalKilosEdit(TK);

        let total_importe = 0;
        const data = agrupadoValues.map((a) => {
            total_importe = total_importe + a.precio * a.cantidad;
            return [
              a.articulos,
              a.unidad,
              a.colores,
              a.modelo,
              a.rollos,
              new Intl.NumberFormat("en-US").format(a.cantidad),
              "$" + new Intl.NumberFormat("en-US").format(a.precio),
              "$" +
                new Intl.NumberFormat("en-US").format(a.precio * a.cantidad),
              a.cantidadString
            ]
        });
        const dataPDF = Object.values(data);
        const dataPDFLimpia = dataPDF.filter(function (el) {
          return el != null;
        });
        const doc = new jsPDF("landscape");
        var img = new Image();
        img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
        doc.addImage(img, "png", 230, 30, 45, 15);
        var img2 = new Image();
        img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
        doc.addImage(img2, "png", 120, 15, 60, 25);
        doc.text(`Compra # ${numero}`, 20, 20);
        doc.setFontSize(12);
        doc.text(`Fecha ${fecha}`, 20, 25);
        doc.text(`Proveedor ${proveedores}`, 20, 30);
        doc.text(
          `Metros ${new Intl.NumberFormat("en-US").format(
            metros
          )} / Rollos ${rollos}`,
          20,
          35
        );
        doc.autoTable({
          head: [
            ["Articulo", "Unidad", "Color", "Modelo", "Rollos", "Cantidad", "Precio", "Total", "Desgloce Rollos"],
          ],
          body: dataPDFLimpia,
          startY: 45,
          foot: [
            [
              "",
              "",
              "",
              "Totales",
              new Intl.NumberFormat("en-US").format(rollos),
              new Intl.NumberFormat("en-US").format(metros),
              "",
              "$" + new Intl.NumberFormat("en-US").format(total_importe),
            ],
          ],
          showFoot: "lastPage",
        });
        doc.save(`Compra-${numero}.pdf`);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function editCompras(event) {
    event.preventDefault();
    const URL_COMPRA_EDIT = `${process.env.REACT_APP_URL_COMPRAS}/${idEdit}`;
    axios
      .all([
        axios.patch(
          URL_COMPRA_EDIT,
          {
            fecha,
            factura_proveedor,
            observaciones,
            pais_origen,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        ),
      ])
      .then(() => {
        axios.post(
          URL_LOGS,
          {
            tipo: "Editar Compra",
            detalle: `${numero_compra} ${fecha}`,
            user: user.id,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        );

        toggleEdit();
        Swal.fire("Good job!", "Actualizado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  const headers = [
    { name: "Activo", field: "activo", sortable: true },
    { name: "Numero", field: "numero", sortable: true },
    { name: "Fecha", field: "fecha", sortable: false },
    { name: "OC", field: "numeroOrden", sortable: true },
    { name: "Proveedor", field: "proveedores", sortable: true },
    { name: "Kilos", field: "kilos", sortable: true },
    { name: "Metros", field: "metros", sortable: true },
    { name: "Recibio", field: "user", sortable: true },
    { name: "Acciones", field: "acciones", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.activo.toLowerCase().includes(search.toLowerCase()) ||
          comment.proveedores.toLowerCase().includes(search.toLowerCase()) ||
          comment.user.toLowerCase().includes(search.toLowerCase()) ||
          comment.numeroOrden.toString().includes(search)||
          comment.kilos.toString().includes(search)||
          comment.metros.toString().includes(search)||
          comment.numero.toString().includes(search)
      );
    }
    if (selectedProveedor) {
      computedComments = computedComments.filter((e) =>
        e.idProveedores.includes(selectedProveedor)
      );
    }
    if (activo) {
      computedComments = computedComments.filter((e) =>
        e.activo.includes(activo)
      );
    }

    if (selectedFechaInicio) {
      computedComments = computedComments.filter(
        (e) => e.fecha >= selectedFechaInicio
      );
    }

    if (selectedFechaFin) {
      computedComments = computedComments.filter(
        (e) => e.fecha <= selectedFechaFin
      );
    }

    setTotalItems(computedComments.length);

    //Sorting comments
    if (sorting.field && sorting.field != "kilos" && sorting.field != "metros" && sorting.field != "numeroOrden" && sorting.field != "numero") {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (sorting.field && sorting.order === "asc" && (sorting.field == "kilos" || sorting.field == "metros" || sorting.field == "numeroOrden" || sorting.field == "numero")) {
      computedComments = computedComments.sort(
        (a, b) => parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (sorting.field && sorting.order === "desc" && (sorting.field == "kilos" || sorting.field == "metros" || sorting.field == "numeroOrden" || sorting.field == "numero")) {
      computedComments = computedComments.sort(
        (a, b) => parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    selectedProveedor,
    activo,
    selectedFechaInicio,
    selectedFechaFin,
  ]);

  function EditArticulos(idC, numero_compra, idOrdenCompra) {
    axios
      .get(`${URL_ARTICULOS_OC}/ordenesCompra/${idOrdenCompra}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allArticulosOC = res.data;
        setArticulosOC(allArticulosOC);
      })
      .catch((err) => {
        console.log(err);
      });

    setInputFields([
      {
        id: uuidv4(),
        articulosOC: "",
        articulos: "",
        unidad: "",
        colores: "",
        cantidad: 0,
        clave: "0",
        captura: 1000000
      },
    ]);
    setIdEdit(idC);
    setNumeroCompra(numero_compra);

    axios
      .get(`${URL_INVENTARIOS}/compras/${idC}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulos = response.data;
        let arrayTabla = allArticulos
          .map((a) => {
            return {
              id: a._id,
              articulosOC: a.articulosOC[0]._id,
              articulos: a.articulos[0]._id,
              unidad: a.articulos[0].unidad,
              colores: a.colores[0]._id,
              cantidad: a.cantidad,
              clave: a.clave,
              recibido: a.articulosOC[0].recibido,
              pendiente_recibir: a.articulosOC[0].pendiente_recibir
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);
        setArticulosCompra(dataFinal);

        let mts = dataFinal.filter((e) => e.unidad == "Metros");
        let totalMts = mts.map((c) => parseFloat(c.cantidad));
        let TM = totalMts.reduce((t, total, index) => t + total, 0);
        setTotalMetrosEdit(TM);
        let KG = dataFinal.filter((e) => e.unidad == "Kilos");
        let totalKG = KG.map((c) => parseFloat(c.cantidad));
        let TK = totalKG.reduce((t, total, index) => t + total, 0);
        setTotalKilosEdit(TK);
      })
      .catch((err) => {
        console.log(err);
      });

    toggleEditArticulos();
  }

  function BuscaArticulo(id, event) {
    articulosOC.map((a) => {
      if (a._id == event.target.value) {
        let articulosOC = a._id;
        let idArticulo = a.articulos[0]._id;
        let unidadArticulo = a.articulos[0].unidad;
        let color = a.colores[0]._id;
        handleChangeInputArticulo(
          id,
          articulosOC,
          idArticulo,
          unidadArticulo,
          color
        );
      }
    });
  }

  const handleChangeInputArticulo = (
    id,
    articulosOC,
    idArticulo,
    unidadArticulo,
    color
  ) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.articulosOC = articulosOC;
        i.articulos = idArticulo;
        i.unidad = unidadArticulo;
        i.colores = color;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  function BuscaArticuloExistentes(id, event) {
    articulosOC.map((a) => {
      if (a._id == event.target.value) {
        let articulosOC = a._id;
        let idArticulo = a.articulos[0]._id;
        let unidadArticulo = a.articulos[0].unidad;
        let color = a.colores[0]._id;
        handleChangeInputArticuloExistentes(
          id,
          articulosOC,
          idArticulo,
          unidadArticulo,
          color
        );
      }
    });
  }

  const handleChangeInputArticuloExistentes = (
    id,
    articulosOC,
    idArticulo,
    unidadArticulo,
    color
  ) => {
    const newArticulosCompra = articulosCompra.map((i) => {
      if (id === i.id) {
        i.articulosOC = articulosOC;
        i.articulos = idArticulo;
        i.unidad = unidadArticulo;
        i.colores = color;
      }
      return i;
    });
    setArticulosCompra(newArticulosCompra);
    ActualizaTotales();
  };

  function ActualizaTotales() {
    let mtsExistentes = articulosCompra.filter((e) => e.unidad == "Metros");
    let mtsNuevos = inputFields.filter((e) => e.unidad == "Metros");
    let mts = mtsExistentes.concat(mtsNuevos);
    let totalMts = mts.map((c) => parseFloat(c.cantidad));
    let TM = totalMts.reduce((t, total, index) => t + total, 0);
    setTotalMetrosEdit(TM);

    let KGExistentes = articulosCompra.filter((e) => e.unidad == "Kilos");
    let KGNuevos = inputFields.filter((e) => e.unidad == "Kilos");
    let KG = KGExistentes.concat(KGNuevos);
    let totalKG = KG.map((c) => parseFloat(c.cantidad));
    let TK = totalKG.reduce((t, total, index) => t + total, 0);
    setTotalKilosEdit(TK);
  }

  const handleChangeInput = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  const handleChangeInputExistentes = (id, event) => {
    const newArticulosCompra = articulosCompra.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });
    setArticulosCompra(newArticulosCompra);
    ActualizaTotales();
  };

  const handleChangeInputExistentesCantidad = async (id, event, idArticulosOC) => {
    let PR = 0
    let r = 0

    const newArticulosCompra = articulosCompra.map((i) => {
      if (id === i.id) {
        PR = parseFloat(i.pendiente_recibir) + parseFloat(i.cantidad) - parseFloat(event.target.value)
        i.pendiente_recibir = PR
        r = parseFloat(i.recibido) - parseFloat(i.cantidad) + parseFloat(event.target.value)
        i.recibido = r
      }
      return i
    })

    const newArticulosCompra2 = newArticulosCompra.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      return i
    });

    const newArticulosCompra3 = newArticulosCompra2.map((i) => {
      if (idArticulosOC == i.articulosOC) {
        i.recibido = r
        i.pendiente_recibir = PR
      }
      return i
    })

    setArticulosCompra(newArticulosCompra3);
    ActualizaTotales();
  };


  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);
    let mts = values.filter((e) => e.unidad == "Metros");
    let totalMts = mts.map((c) => parseFloat(c.cantidad));
    let TM = totalMts.reduce((t, total, index) => t + total, 0);
    setTotalMetrosEdit(TM);
    let KG = values.filter((e) => e.unidad == "Kilos");
    let totalKG = KG.map((c) => parseFloat(c.cantidad));
    let TK = totalKG.reduce((t, total, index) => t + total, 0);
    setTotalKilosEdit(TK);
  };

  const handleAddFields = () => {
    let TC = totalCaptura +1
    const ultimo = inputFields[inputFields.length-1];
    setTotalCaptura(TC)
    setInputFields([
      ...inputFields,
      {
        id: uuidv4(),
        articulosOC: ultimo.articulosOC,
        articulos: ultimo.articulos,
        unidad:ultimo.unidad,
        colores: ultimo.colores,
        cantidad: 0,
        clave: ultimo.clave,
        captura:TC
      },
    ]);
  };

  const saveCompra = async (event) => {
    event.preventDefault();
    setValidaBoton(false);
    toggleProgreso();
    let totalCompras = inputFields.length + articulosCompra.length
    const URL_COMPRA_EDIT = `${process.env.REACT_APP_URL_COMPRAS}/${idEdit}`;
    try {
      await axios
        .patch(
          URL_COMPRA_EDIT,
          {
            total_kilos: total_kilos_edit.toFixed(2),
            total_metros: total_metros_edit.toFixed(2),
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then(() => {
          inputFields.map((a) => {
            if(a.articulos != "" && a.cantidad !=0){
              axios.post(
                URL_INVENTARIOS,
                {
                  compras: idEdit,
                  articulos: a.articulos,
                  articulosOC: a.articulosOC,
                  colores: a.colores,
                  cantidad: parseFloat(a.cantidad),
                  clave: a.clave,
                  captura: a.captura
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                  },
                }
              )
              .then(()=>{
                totalCompras = totalCompras -1
                if(totalCompras == 0 ){
                  axios.post(
                    URL_LOGS,
                    {
                      tipo: "Editar Compra",
                      detalle: `${numero_compra}`,
                      user: user.id,
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                      },
                    }
                  );
                  Swal.fire("Good job!", "Creado con exito", `success`);
                  setTimeout(() => {
                    window.location.reload();
                  }, 1000);
                }
              })
            } else {
              totalCompras = totalCompras -1
              if(totalCompras == 0 ){
                axios.post(
                  URL_LOGS,
                  {
                    tipo: "Editar Compra",
                    detalle: `${numero_compra}`,
                    user: user.id,
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                    },
                  }
                );
                Swal.fire("Good job!", "Creado con exito", `success`);
                setTimeout(() => {
                  window.location.reload();
                }, 1000);
              }
            }
          });
          articulosCompra.map((a) => {
            // Quitar esto
            axios.patch(
              `${URL_ARTICULOS_OC}/${a.articulosOC}`,
              {
                recibido: a.recibido,
                pendiente_recibir: a.pendiente_recibir,
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                },
              }
            )
            axios.patch(
              `${URL_INVENTARIOS}/${a.id}`,
              {
                articulos: a.articulos,
                articulosOC: a.articulosOC,
                colores: a.colores,
                cantidad: parseFloat(a.cantidad),
                clave: a.clave,
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                },
              }
            )
            .then(()=>{
              totalCompras = totalCompras -1
              if(totalCompras == 0 ){
                axios.post(
                  URL_LOGS,
                  {
                    tipo: "Editar Compra",
                    detalle: `${numero_compra}`,
                    user: user.id,
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                    },
                  }
                );
                Swal.fire("Good job!", "Creado con exito", `success`);
                setTimeout(() => {
                  window.location.reload();
                }, 1000);
              }
            })
          });
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `${error.response.data}`,
          });
          console.log(error);
        });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `${error.response.data}`,
      });
      console.log(error);
    }
  };


  function QRGen(idCompra) {
    axios
      .get(`${URL_INVENTARIOS}/compras/${idCompra}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allRollos = response.data;
        setRollos(allRollos);

        const doc = new jsPDF("p", "mm", [102,80])
        let alturaLogo = 30;
        let alturaImagen = 60;
        let alturaTexto1 = 43;
        let alturaTexto2 = 48;
        let alturaTexto3 = 53;
        let alturaTexto4 = 58;
        let alturaTexto5 = 68;
        let alturaLogo2 = 35;

        allRollos
        .sort((a, b) => (a.captura > b.captura ? 1 : -1))
        .map((a) => {
          let codigo = a._id;
          var base64 = qrcode.toDataURL(codigo);
          var img = new Image();
          img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
          doc.addImage(img, "png", 70, alturaLogo, 35, 10,null,null,90);

          var img2 = new Image();
          img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
          doc.addImage(img2, "png", 40, alturaLogo2, 60, 25,null,null,90);

          var img3 = new Image();
          img3.src = base64;
          doc.addImage(img3, "png", 0, alturaImagen, 40, 40);

          doc.setFontSize(11);
          doc.text(`Cod ${a.articulos[0].codigo}`, alturaTexto1,90,null,90);
          doc.text(`Mod ${a.articulos[0].nombre}`, alturaTexto2,90,null,90);
          doc.text(`Color ${a.colores[0].name} / Clave ${a.clave}`, alturaTexto3,90,null,90);
          doc.text(`ID ${a._id}`, alturaTexto4,90,null,90);

          doc.setFontSize(18);
          doc.text(`Cant. ${a.cantidad} ${a.articulos[0].unidad}`, alturaTexto5,90,null,90);
          doc.addPage();
        });
        doc.save(`QR.pdf`);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // function desarrollo(){
  //   comments.map((a)=>{
  //     axios.patch(
  //       `${URL_COMPRAS}/${a._id}`,
  //       {
  //         proveedores: a.idProveedores,
  //       },
  //       {
  //         headers: {
  //           Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
  //         },
  //       }
  //     )
  //   .then(() => {
  //     Swal.fire("Good job!", "Enviado con exito", "success");
  //   })
  //   .catch((error) => {
  //     Swal.fire({
  //       icon: "error",
  //       title: "Oops...",
  //       text: "Something went wrong!",
  //     });
  //     console.log(error);
  //   });
   
  // })
  // }

  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_compras ? (
        <div className="card col-12">
          <Row>
            <Col md={8}>
              {user.compras_create ? (
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  href="/ComprasCreate"
                >
                  Nueva Recepcion
                </Button>
              ) : (
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  disabled
                >
                  Nueva Recepcion
                </Button>
              )}
              <Button
                size="sm"
                href="/MenuCompras"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>Enviar Listado Ordenes de Compra</h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={enviaMail}>
                Enviar
              </Button>
            </ModalFooter>
          </Modal>
          <h3 align="center">Compras</h3>
          <div className="row">
            <div className="col-md-8">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
            <div className="col-md-4 d-flex flex-row-reverse">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
          </div>
          <Row>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Inicio</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaInicio}
                required
                onChange={(e) => {
                  setSelectedFechaInicio(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Fin</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaFin}
                required
                onChange={(e) => {
                  setSelectedFechaFin(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label>Proveedores</Label>
              <Input
                bsSize="sm"
                type="select"
                value={selectedProveedor}
                onChange={(e) => {
                  setSelectedProveedor(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="0">Selecciona</option>
                {proveedores
                  .sort((a, b) => (a.razon_social > b.razon_social ? 1 : -1))
                  .map((a) => {
                    return <option value={a._id}>{a.razon_social}</option>;
                  })}
              </Input>
            </Col>

            <Col md={2}>
              <Label>Activo</Label>
              <Input
                bsSize="sm"
                type="select"
                value={activo}
                required
                onChange={(e) => {
                  setActivo(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="">Selecciona</option>
                <option value="Si">Activos</option>
                <option value="No">Inactivos</option>
              </Input>
            </Col>
          </Row>
          <br />
          <Table size="sm" striped borderless className="table-responsive-xl">
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
            <tbody>
              {commentsData.map((a) => {
                {
                  return (
                    <tr>
                      <td>{a.activo}</td>
                      <td>{a.numero}</td>
                      <td>{a.fecha}</td>
                      <td>{a.numeroOrden}</td>
                      <td>{a.proveedores}</td>
                      <td>{new Intl.NumberFormat("en-US").format(a.kilos)}</td>
                      <td>{new Intl.NumberFormat("en-US").format(a.metros)}</td>
                      <td>{a.user}</td>
                      <td>
                        {user.compras_create ? (
                          <div>
                            <Button
                              size="sm"
                              className="btn"
                              color="success"
                              onClick={(e) => QRGen(a._id)}
                            >
                              <i class="fas fa-qrcode"></i>
                            </Button>
                            <Button
                              size="sm"
                              className="btn"
                              color="danger"
                              onClick={(e) =>
                                PDFOC(
                                  a.fecha,
                                  a.idProveedores,
                                  a.proveedores,
                                  a.kilos,
                                  a.metros,
                                  a.observaciones,
                                  a.numero,
                                  a._id
                                )
                              }
                            >
                              <i class="far fa-file-pdf"></i>
                            </Button>
                            <Button
                              color="info"
                              id="Editar"
                              size="sm"
                              onClick={(e) =>
                                jalaInfo(
                                  a.fecha,
                                  a.factura_proveedor,
                                  a.observaciones,
                                  a.numero,
                                  a.pais_origen,
                                  a._id
                                )
                              }
                            >
                              <i class="far fa-edit"></i>
                            </Button>

                            {/* <Button
                              color="info"
                              id="Editar"
                              size="sm"
                              onClick={(e) =>
                                EditArticulos(
                                  a._id,
                                  a.numero,
                                  a.idOrdenesCompra
                                )
                              }
                            >
                              <i class="fas fa-cart-plus"></i>
                            </Button> */}

                            {/* <Baja
                              idStatus={a._id}
                              is_active={a.activo}
                              URL_BAJA={process.env.REACT_APP_URL_COMPRAS}
                            /> */}
                            <BajaAltaMultiple
                              idStatus={a._id}
                              is_active={a.activo}
                              URL_BAJA={process.env.REACT_APP_URL_COMPRAS}
                              hijo={`${process.env.REACT_APP_URL_INVENTARIOS}/baja`}
                            />
                          </div>
                        ) : (
                          <div>
                            <Button color="info" id="Editar" size="sm" disabled>
                              <i class="far fa-edit"></i>
                            </Button>
                          </div>
                        )}
                      </td>
                    </tr>
                  );
                }
              })}
            </tbody>
          </Table>
          <div className="col-md-6">
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      ) : undefined}
      <br />

      <Modal size="lg" isOpen={modalEdit} toggle={toggleEdit}>
        <ModalHeader toggle={toggleEdit}>
          <h4>Editar Compra # {numero_compra}</h4>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md={3}>
              <Label>Fecha</Label>
              <Input
                type="date"
                placeholder="Fecha"
                value={fecha}
                required
                onChange={(e) => {
                  setFecha(e.target.value);
                }}
              />
            </Col>

            <Col md={3}>
              <Label>Factura Proveedor</Label>
              <Input
                type="text"
                placeholder="Factura Proveedor"
                value={factura_proveedor}
                required
                onChange={(e) => {
                  setFacturaProveedor(e.target.value);
                }}
              />
            </Col>

            <Col md={3}>
              <Label>Pais Origen</Label>
              <Input
                type="text"
                placeholder="Pais Origen"
                value={pais_origen}
                required
                onChange={(e) => {
                  setPaisOrigen(e.target.value);
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Label for="exampleEmail" className="mr-sm-2">
                Observaciones
              </Label>
              <Input
                type="textarea"
                placeholder="Observaciones"
                value={observaciones}
                required
                onChange={(e) => {
                  setObservaciones(e.target.value);
                }}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={editCompras}>
            Guardar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        size="xxl"
        isOpen={modalEditArticulos}
        toggle={toggleEditArticulos}
      >
        <ModalHeader toggle={toggleEditArticulos}>
          <h4>Editar Articulos Compra # {numero_compra}</h4>
          <h4>
            TOTALES {total_kilos_edit.toFixed(2)} kg. /{" "}
            {total_metros_edit.toFixed(2)} mts.
          </h4>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md={4}>
              <Label className="mr-sm-2">Articulos</Label>
            </Col>
            <Col md={1}>
              <Label className="mr-sm-2">Unidad</Label>
            </Col>
            <Col md={1}>
              <Label className="mr-sm-2">Cantidad</Label>
            </Col>
            <Col md={1}>
              <Label className="mr-sm-2">Clave</Label>
            </Col>
          </Row>

          {/* Articulos Existentes*/}

          {articulosCompra.map((ac) => (
            <div key={ac.id}>
              <Row>
                <Col md={4}>
                  <Input
                    bsSize="sm"
                    name="articulos"
                    type="select"
                    value={ac.articulosOC}
                    required
                    onChange={(event) => {
                      BuscaArticuloExistentes(ac.id, event);
                    }}
                    disabled
                  >
                    <option value="">Selecciona un Articulo</option>
                    {articulosOC
                      .sort((a, b) =>
                        a.articulos[0].codigo > b.articulos[0].codigo ? 1 : -1
                      )
                      .map((a) => {
                        return (
                          <option value={a._id}>
                            {a.articulos[0].codigo} / {a.articulos[0].nombre} /{" "}
                            {a.colores[0].name}
                          </option>
                        );
                      })}
                  </Input>
                </Col>
                <Col md={1}>
                  <Input
                    bsSize="sm"
                    name="unidad"
                    type="string"
                    value={ac.unidad}
                    disabled
                  ></Input>
                </Col>

                <Col md={1}>
                  <Input
                    bsSize="sm"
                    name="cantidad"
                    type="number"
                    placeholder="Cantidad"
                    value={ac.cantidad}
                    required
                    onChange={(event) =>
                      handleChangeInputExistentesCantidad(ac.id, event, ac.articulosOC)
                    }
                  />
                </Col>

                <Col md={1}>
                  <Input
                    bsSize="sm"
                    name="clave"
                    type="text"
                    placeholder="Clave"
                    value={ac.clave}
                    required
                    onChange={(event) =>
                      handleChangeInputExistentes(ac.id, event)
                    }
                  />
                </Col>

              </Row>
            </div>
          ))}

          {/* Agregar mas articulos */}

          {inputFields.map((inputField) => (
            <div key={inputField.id}>
              <Row>
                <Col md={4}>
                  <Input
                    bsSize="sm"
                    name="articulos"
                    type="select"
                    value={inputField.articulosOC}
                    required
                    onChange={(event) => {
                      BuscaArticulo(inputField.id, event);
                    }}
                  >
                    <option value="">Selecciona un Articulo</option>
                    {articulosOC
                      .sort((a, b) =>
                        a.articulos[0].codigo > b.articulos[0].codigo ? 1 : -1
                      )
                      .map((a) => {
                        return (
                          <option value={a._id}>
                            {a.articulos[0].codigo} / {a.articulos[0].nombre} /{" "}
                            {a.colores[0].name}
                          </option>
                        );
                      })}
                  </Input>
                </Col>
                <Col md={1}>
                  <Input
                    bsSize="sm"
                    name="unidad"
                    type="string"
                    value={inputField.unidad}
                    disabled
                  ></Input>
                </Col>

                <Col md={1}>
                  <Input
                    bsSize="sm"
                    name="cantidad"
                    type="number"
                    placeholder="Cantidad"
                    value={inputField.cantidad}
                    required
                    onChange={(event) =>
                      handleChangeInput(inputField.id, event)
                    }
                  />
                </Col>

                <Col md={1}>
                  <Input
                    bsSize="sm"
                    name="clave"
                    type="text"
                    placeholder="Clave"
                    value={inputField.clave}
                    required
                    onChange={(event) =>
                      handleChangeInput(inputField.id, event)
                    }
                  />
                </Col>


                <Col>
                  <Button
                    size="sm"
                    className="btn"
                    color="danger"
                    disabled={inputFields.length === 1}
                    onClick={() => handleRemoveFields(inputField.id)}
                    tabindex="-1"
                  >
                    <i class="fas fa-minus"></i>
                  </Button>
                  <Button
                    size="sm"
                    className="btn"
                    color="info"
                    onClick={handleAddFields}
                    tabindex="-1"
                  >
                    <i class="fas fa-plus"></i>
                  </Button>
                </Col>
              </Row>
            </div>
          ))}
          <Row>
            <Col md={12}>
              <h4 id="logoutBoton">
                TOTALES{" "}
                {new Intl.NumberFormat("en-US").format(
                  total_kilos_edit.toFixed(2)
                )}{" "}
                kg. /{" "}
                {new Intl.NumberFormat("en-US").format(
                  total_metros_edit.toFixed(2)
                )}{" "}
                mts.
              </h4>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
        {validaBoton ? (
              <Button type="submit" className="btn btn-success" onClick={saveCompra}>
                Guardar
              </Button>
            ) : (
              <Button type="submit" className="btn btn-success" disabled>
                Guardar
              </Button>
            )}
        </ModalFooter>
      </Modal>

   <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
             <h4> Este proceso puede tardar varios minutos.
              <br />
              Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
              </div>
            </ModalBody>
          </Modal>

      {loader}
    </>
  );
}

export default ListadoCompras;
