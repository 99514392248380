import React, { useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { Col, Row, Button } from "reactstrap";
import Header from "../../layout/Header/Header";

function BotonesReportes() {
  const { user } = useContext(AuthContext);
  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_reportes ? (
      <div className="container">
            <Row>
            <Col md={4} align="center">
            {user.menu_reportes ?(
              <Button
                href="/TablaVentas"
                className="botonesMenu"
                color="success"
              >
                <i class="fas fa-chart-line fa-7x"></i>
                <br />
                <br />
                Ventas
              </Button>
            ): <Button
            disabled
            className="botonesMenu"
            color="success"
          >
            <i class="fas fa-chart-line fa-7x"></i>
            <br />
            <br />
            Ventas
          </Button> }
            </Col>

            <Col md={4} align="center">
            {user.menu_reportes ?(
              <Button
                href="/TablaDevoluciones"
                className="botonesMenu"
                color="success"
              >
                <i class="fas fa-chart-bar fa-7x"></i>
                <br />
                <br />
                Devoluciones
              </Button>
            ): <Button
            disabled
            className="botonesMenu"
            color="success"
          >
            <i class="fas fa-chart-bar fa-7x"></i>
            <br />
            <br />
            Devoluciones
          </Button> }
            </Col>
            </Row>
            <br />
      </div>
      ) : undefined}
    </>
  );
}

export default BotonesReportes;
