import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import axios from "axios";
import Header from "../../layout/Header/Header";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_dataviz from "@amcharts/amcharts4/themes/dataviz";
import am4themes_material from "@amcharts/amcharts4/themes/material";
import am4themes_kelly from "@amcharts/amcharts4/themes/kelly";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Form,
} from "reactstrap";
import moment from 'moment';
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";

// am4core.useTheme(am4themes_dataviz);
am4core.useTheme(am4themes_animated);
// am4core.useTheme(am4themes_material);

function GraficaVentas() {
  let hoy = new Date()
  let inicio = new Date()
  inicio.setDate(inicio.getDate()-30);
 
  let endDate   = moment(hoy).format("YYYY-MM-DD"); 
  let startDate   = moment(inicio).format("YYYY-MM-DD");

  const { user } = useContext(AuthContext);
  const URL_SURTIDOS_ACUMULADOS = process.env.REACT_APP_URL_SURTIDOS_ACUMULADOS;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_COLORES = process.env.REACT_APP_URL_COLORES;
  const URL_ARTICULOS = process.env.REACT_APP_URL_ARTICULOS;

  const [graficaCliente, setGraficaCliente] = useState([]);
  const [graficaArticulo, setGraficaArticulo] = useState([]);
  const [graficaColor, setGraficaColor] = useState([]);

  const [selectedFechaInicio, setSelectedFechaInicio] = useState(startDate);
  const [selectedFechaFin, setSelectedFechaFin] = useState(endDate);  
  
  const [clientes, setClientes] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState("");

  const [articulos, setArticulos] = useState([]);
  const [selectedArticulo, setSelectedArticulo] = useState("");

  const [colores, setColores] = useState([]);
  const [selectedColor, setSelectedColor] = useState("");

useMemo(()=>{
  axios
  .get(`${URL_SURTIDOS_ACUMULADOS}Reporte/${selectedFechaInicio}/${selectedFechaFin}`, {
    headers: {
      Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
    },
  })
  .then((response) => {
    let allGraficas = response.data;
    let arrayTabla = allGraficas
    .map((a) => {
      if(
        (selectedCliente == 0 || selectedCliente == a.pedidos[0].clientes[0]._id) &&
        (selectedArticulo == 0 || selectedArticulo == a.articulos[0]._id) &&
        (selectedColor == 0 || selectedColor == a.colores[0]._id)
        ){
      return {
        _id:a._id,
        idCliente: a.pedidos[0].clientes[0]._id,
        cliente: a.pedidos[0].clientes[0].razon_social,
        idArticulo: a.articulos[0]._id,
        articulo: a.articulos[0].codigo + " / " + a.articulos[0].nombre,
        idColor: a.colores[0]._id,
        color: a.colores[0].name,
        cantidad: a.cantidad
      }}
    }).filter(function (el) {
      return el != null;
    });

    let dataFinal = Object.values(arrayTabla);

    let agrupadoCliente = dataFinal.reduce(function (groups, item) {
      const val = item["idCliente"];
      groups[val] = groups[val] || {
        idCliente: item.idCliente,
        cantidad: 0,
      };
      groups[val].cantidad += item.cantidad;
      groups[val].idCliente = item.idCliente;
      groups[val].cliente = item.cliente;
      return groups;
    },[])

    let arrayCliente = Object.values(agrupadoCliente)
    let arrayClienteSorteado = arrayCliente.sort((a, b) => (a.cantidad < b.cantidad ? 1 : -1))

    setGraficaCliente(arrayClienteSorteado);

    let agrupadoArticulo = dataFinal.reduce(function (groups, item) {
      const val = item["idArticulo"];
      groups[val] = groups[val] || {
        idArticulo: item.idArticulo,
        cantidad: 0,
      };
      groups[val].cantidad += item.cantidad;
      groups[val].idArticulo = item.idArticulo;
      groups[val].articulo = item.articulo;
      return groups;
    },[])

    let arrayArticulo = Object.values(agrupadoArticulo)
    let arrayArticuloSorteado = arrayArticulo.sort((a, b) => (a.cantidad < b.cantidad ? 1 : -1))

    setGraficaArticulo(arrayArticuloSorteado);

    let agrupadoColor = dataFinal.reduce(function (groups, item) {
      const val = item["idColor"];
      groups[val] = groups[val] || {
        idColor: item.idColor,
        cantidad: 0,
      };
      groups[val].cantidad += item.cantidad;
      groups[val].idColor = item.idColor;
      groups[val].color = item.color;
      return groups;
    },[])

    let arrayColor = Object.values(agrupadoColor)
    let arrayColorSorteado = arrayColor.sort((a, b) => (a.cantidad < b.cantidad ? 1 : -1))

    setGraficaColor(arrayColorSorteado);
  })
  .catch((err) => {
    console.log(err);
  });

  axios
  .get(URL_CLIENTES, {
    headers: {
      Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
    },
  })
  .then((res) => {
    const allClientes = res.data;
    setClientes(allClientes);
  })
  .catch((err) => {
    console.log(err);
  });

  axios
  .get(URL_ARTICULOS, {
    headers: {
      Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
    },
  })
  .then((res) => {
    const allArticulos = res.data;
    setArticulos(allArticulos);
  })
  .catch((err) => {
    console.log(err);
  });

  axios
  .get(URL_COLORES, {
    headers: {
      Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
    },
  })
  .then((res) => {
    const allColores = res.data;
    setColores(allColores);
  })
  .catch((err) => {
    console.log(err);
  });
  
}, [selectedFechaInicio, selectedFechaFin, selectedCliente, selectedArticulo, selectedColor])

const Toast = Swal.mixin({
  toast: true,
  position: 'center',
  showConfirmButton: false,
  timer: 5000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})

Toast.fire({
  icon: 'success',
  title: 'Danos unos segundos....'
})


// Grafica Clientes
  let chart = am4core.create("chartdivClientes", am4charts.XYChart3D);

  chart.data = graficaCliente

  let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
  categoryAxis.dataFields.category = "cliente";

  categoryAxis.events.on("sizechanged", function (ev) {
    let axis = ev.target;
    var cellWidth = axis.pixelWidth / (axis.endIndex - axis.startIndex);
    if (cellWidth < axis.renderer.labels.template.maxWidth) {
      axis.renderer.labels.template.rotation = -45;
      axis.renderer.labels.template.horizontalCenter = "right";
      axis.renderer.labels.template.verticalCenter = "middle";
    } else {
      axis.renderer.labels.template.rotation = 0;
      axis.renderer.labels.template.horizontalCenter = "middle";
      axis.renderer.labels.template.verticalCenter = "top";
    }
  });

  let label = categoryAxis.renderer.labels.template;
  label.truncate = true;
  label.maxWidth = 100;

  let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

  let series = chart.series.push(new am4charts.ColumnSeries3D());
  series.columns.template.tooltipText =
    "Cliente: {categoryX}\nCantidad: {valueY}";
  series.dataFields.valueY = "cantidad";
  series.dataFields.categoryX = "cliente";
  series.name = "Cantidad";
  series.clustered = false;

  // Add legend
  chart.legend = new am4charts.Legend();
  chart.exporting.menu = new am4core.ExportMenu();
  chart.exporting.filePrefix = "myExport";
  chart.scrollbarX = new am4core.Scrollbar();

  // Termina Grafica Clientes


  // Grafica Articulos
  let chartArticulos = am4core.create("chartdivArticulos", am4charts.XYChart3D);

  chartArticulos.data = graficaArticulo

  let categoryAxisArticulos = chartArticulos.xAxes.push(new am4charts.CategoryAxis());
  categoryAxisArticulos.dataFields.category = "articulo";

  categoryAxisArticulos.events.on("sizechanged", function (ev) {
    let axisArticulos = ev.target;
    var cellWidth = axisArticulos.pixelWidth / (axisArticulos.endIndex - axisArticulos.startIndex);
    if (cellWidth < axisArticulos.renderer.labels.template.maxWidth) {
      axisArticulos.renderer.labels.template.rotation = -45;
      axisArticulos.renderer.labels.template.horizontalCenter = "right";
      axisArticulos.renderer.labels.template.verticalCenter = "middle";
    } else {
      axisArticulos.renderer.labels.template.rotation = 0;
      axisArticulos.renderer.labels.template.horizontalCenter = "middle";
      axisArticulos.renderer.labels.template.verticalCenter = "top";
    }
  });

  let labelArticulos = categoryAxisArticulos.renderer.labels.template;
  labelArticulos.truncate = true;
  labelArticulos.maxWidth = 100;

  let valueAxisArticulos = chartArticulos.yAxes.push(new am4charts.ValueAxis());

  let seriesArticulos = chartArticulos.series.push(new am4charts.ColumnSeries3D());
  seriesArticulos.columns.template.tooltipText =
    "Articulo: {categoryX}\nCantidad: {valueY}";
  seriesArticulos.dataFields.valueY = "cantidad";
  seriesArticulos.dataFields.categoryX = "articulo";
  seriesArticulos.name = "Cantidad";
  seriesArticulos.clustered = false;

  // Add legend
  chartArticulos.legend = new am4charts.Legend();
  chartArticulos.exporting.menu = new am4core.ExportMenu();
  chartArticulos.exporting.filePrefix = "myExport";
  chartArticulos.scrollbarX = new am4core.Scrollbar();

  // Termina Grafica Articulos

    // Grafica Colores
    let chartColores = am4core.create("chartdivColores", am4charts.XYChart3D);

    chartColores.data = graficaColor
  
    let categoryAxisColores = chartColores.xAxes.push(new am4charts.CategoryAxis());
    categoryAxisColores.dataFields.category = "color";
  
    categoryAxisColores.events.on("sizechanged", function (ev) {
      let axisColores = ev.target;
      var cellWidth = axisColores.pixelWidth / (axisColores.endIndex - axisColores.startIndex);
      if (cellWidth < axisColores.renderer.labels.template.maxWidth) {
        axisColores.renderer.labels.template.rotation = -45;
        axisColores.renderer.labels.template.horizontalCenter = "right";
        axisColores.renderer.labels.template.verticalCenter = "middle";
      } else {
        axisColores.renderer.labels.template.rotation = 0;
        axisColores.renderer.labels.template.horizontalCenter = "middle";
        axisColores.renderer.labels.template.verticalCenter = "top";
      }
    });
  
    let labelColores = categoryAxisColores.renderer.labels.template;
    labelColores.truncate = true;
    labelColores.maxWidth = 100;
  
    let valueAxisColores = chartColores.yAxes.push(new am4charts.ValueAxis());
  
    let seriesColores = chartColores.series.push(new am4charts.ColumnSeries3D());
    seriesColores.columns.template.tooltipText =
      "Color: {categoryX}\nCantidad: {valueY}";
    seriesColores.dataFields.valueY = "cantidad";
    seriesColores.dataFields.categoryX = "color";
    seriesColores.name = "Cantidad";
    seriesColores.clustered = false;
  
    // Add legend
    chartColores.legend = new am4charts.Legend();
    chartColores.exporting.menu = new am4core.ExportMenu();
    chartColores.exporting.filePrefix = "myExport";
    chartColores.scrollbarX = new am4core.Scrollbar();
  
    // Termina Grafica Colores


  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_reportes ? (
        <div className="card container col-12">
          <h3 align="center">Resumen de Ventas</h3>
          <div className="container col-12">
            <Row>
              <Col md={2}>
                <Label className="mr-sm-2">Fecha Inicio</Label>
                <Input
                  bsSize="sm"
                  type="date"
                  value={selectedFechaInicio}
                  required
                  onChange={(e) => {
                    setSelectedFechaInicio(e.target.value);
                  }}
                />
              </Col>
              <Col md={2}>
                <Label className="mr-sm-2">Fecha Fin</Label>
                <Input
                  bsSize="sm"
                  type="date"
                  value={selectedFechaFin}
                  required
                  onChange={(e) => {
                    setSelectedFechaFin(e.target.value);
                  }}
                />
              </Col>
              <Col md={2}>
                <Label className="mr-sm-2">Cliente</Label>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedCliente}
                  required
                  onChange={(e) => {
                    setSelectedCliente(e.target.value);
                  }}
                >
                  <option value="">Selecciona</option>
                  {clientes
                    .sort((a, b) => (a.razon_social > b.razon_social ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.razon_social}</option>;
                    })}
                </Input>
              </Col>
              <Col md={2}>
                <Label className="mr-sm-2">Articulo</Label>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedArticulo}
                  required
                  onChange={(e) => {
                    setSelectedArticulo(e.target.value);
                  }}
                >
                  <option value="">Selecciona</option>
                  {articulos
                    .sort((a, b) => (a.codigo > b.codigo ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.codigo} / {a.nombre}</option>;
                    })}
                </Input>
              </Col>
              <Col md={2}>
                <Label className="mr-sm-2">Color</Label>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedColor}
                  required
                  onChange={(e) => {
                    setSelectedColor(e.target.value);
                  }}
                >
                  <option value="">Selecciona</option>
                  {colores
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
              </Col>
            </Row>
          </div>
          <div
            id="chartdivClientes"
            style={{ width: "100%", height: "500px", paddingTop: "0px" }}
          ></div>
          <div
            id="chartdivArticulos"
            style={{ width: "100%", height: "500px", paddingTop: "0px" }}
          ></div>
          <div
            id="chartdivColores"
            style={{ width: "100%", height: "500px", paddingTop: "0px" }}
          ></div>
        </div>
      ) : undefined}
    </>
  );
}

export default GraficaVentas;
